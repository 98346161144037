<template>
  <div>
    <div class="modal-vue">
      <!-- overlay -->
      <div class="overlay" v-if="showCategoryItemModal" @click="showCategoryItemModal = false"></div>
      <!-- modal -->
      <div class="modal-own" v-if="showCategoryItemModal">
        <h5 style="display: inline-block">{{modalTitle}}</h5>
        <i class="fa fa-times float-right" style="font-size: 2rem; cursor: pointer" @click="cancelCustomModal" aria-hidden="true"></i>
        <form>
          <div class="form-group" v-if="!isToppingsModal">
            <label for="exampleInputEmail1">Title</label>
            <input type="text" class="form-control" id="exampleInputEmail1" v-model="title" placeholder="Enter title">
          </div>
          <div class="form-group" v-if="!isToppingsModal">
            <label for="exampleInputEmail1">Item Number</label>
            <span v-if="latestShortId"> (Last number {{ latestShortId }})</span>
            <input type="number" class="form-control" id="exampleInputEmail1" v-model="shortId" placeholder="Only number">
          </div>
          <div class="form-group" v-if="!isToppingsModal">
            <label for="exampleFormControlTextarea1" >Description</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" v-model="description"></textarea>
          </div>
          <div class="form-group" v-if="(isSelectionModal || isItemModal) && !isToppingsModal">
            <label for="exampleInputEmail1">Base Price</label>
            <input type="number" class="form-control" id="exampleInputEmail1" v-model="basePrice" placeholder="Enter Price">
          </div>
          <div class="form-group" v-if="(isSelectionModal || isItemModal) && !isToppingsModal">
            <input type="checkbox" v-model="specialItem"> Special Item
          </div>

          <div class="row" v-if="(isSelectionModal || isItemModal) && !isToppingsModal">
            <div class="col-md-4 py-4">
              <input type="checkbox" v-model="isSetDiffPrice" @click="setDiffPrice"> Different price
            </div>
            <div class="col-md-4">
              <div v-if="isSetDiffPrice" class="form-group">
                <label>In price</label>
                <input type="number" class="form-control" v-model="inPrice" placeholder="In price">
              </div>
            </div>
            <div class="col-md-4">
              <div v-if="isSetDiffPrice" class="form-group">
                <label>Out price</label>
                <input type="number" class="form-control" v-model="outPrice" placeholder="Out price">
              </div>
            </div>
          </div>

          <div class="row" v-if="isCategoryModal && !isToppingsModal">
            <div class="col-md-6">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Send Terminal</label>
                <select class="form-control" id="exampleFormControlSelect1" v-model="terminalSelected">
                  <option v-for="(item, index) in assignOption" :key="index" :value="item.identity"> {{item.name}} </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Send Status</label>
                <select class="form-control" id="exampleFormControlSelect1" v-model="sendStatusSelected">
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" v-if="!isToppingsModal">
            <div class="col-md-4">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Print Option</label>
                <select class="form-control" id="exampleFormControlSelect1" @change="chooseItems()" v-model="printIdSelected">
                  <option v-for="(item, index) in printOptionDataList" :key="index" :value="item.id"> {{item.title}} </option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" v-if="isItemModal || isSelectionModal">
                <label for="exampleFormControlSelect1" >Assign to</label>
                <select class="form-control" id="exampleFormControlSelect1" v-model="assignSelected">
                  <option v-for="(item, index) in assignOption" :key="index" :value="item.identity"> {{item.name}} </option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group" v-if="isItemModal || isSelectionModal">
                <label for="exampleFormControlSelect1">Status</label>
                <select class="form-control" id="exampleFormControlSelect1" v-model="statusSelected">
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
              </div>
            </div>
            <div v-if="isCategoryModal || isItemModal || isSelectionModal" class="col-md-4">
              <div class="form-group">
                <label>Available day</label>
                <div class="btn-group availableDays">
                  <button type="button" @click="toggleDayList" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    Days
                  </button>
                  <div class="dropdown-menu" :style="{visibility: isAvailableDayClicked == true ? 'visible' : 'hidden'}">
                    All <input type="checkbox" v-model="availableDays.all" @click="() => selectAvailableDay('All')">
                    <div class="dropdown-divider"></div>
                    Sun <input type="checkbox" v-model="availableDays.sun" @click="() => selectAvailableDay('Sun')"><br>
                    Mon <input type="checkbox" v-model="availableDays.mon" @click="() => selectAvailableDay('Mon')"><br>
                    Tue <input type="checkbox" v-model="availableDays.tue" @click="() => selectAvailableDay('Tue')"><br>
                    Wed <input type="checkbox" v-model="availableDays.wed" @click="() => selectAvailableDay('Wed')"><br>
                    Thu <input type="checkbox" v-model="availableDays.thu" @click="() => selectAvailableDay('Thu')"><br>
                    Fri <input type="checkbox" v-model="availableDays.fri" @click="() => selectAvailableDay('Fri')"><br>
                    Sat <input type="checkbox" v-model="availableDays.sat" @click="() => selectAvailableDay('Sat')"><br>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="isToppingsModal">
              <div class="col-md-12">
                  <div class="form-group">
                      <label for="exampleFormControlSelect1">Category</label>
                      <input type="text" class="form-control" v-model="toppingsCategory">
                  </div>
                  <div class="row">
                      <div class="col-md-6">
                          <div class="form-group">
                              <label for="exampleInputEmail1">Toppings Title</label>
                              <input type="text" class="form-control" id="exampleInputEmail1" v-model="toppingsTitle" aria-describedby="emailHelp" placeholder="Enter title">
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="form-group">
                              <label for="exampleInputEmail1">Base Price</label>
                              <input type="number" class="form-control" id="exampleInputEmail1" v-model="toppingsBasePrice" aria-describedby="emailHelp" placeholder="Enter price">
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <button type="button" class="btn submitBtn" v-if="!isToppingsModal && !isCategoryModal && !isItemModal && !isSelectionModal">Submit</button>&nbsp;
          <button type="button" class="btn submitBtn" v-if="isCategoryModal || isItemModal || isSelectionModal" @click="createCategory">Submit</button>&nbsp;
          <button type="button" class="btn submitBtn" v-if="isToppingsModal" @click="createToppings">Submit</button>&nbsp;
          <button type="button" class="btn cancelBtn" @click="cancelCustomModal">Cancel</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
  props: ['modalTitle', 'isCategoryModal', 'isItemModal', 'isSelectionModal', 'isToppingsModal', 'categoryDataList', 'itemDataList', 'printOptionDataList', 'selectedItemPrintOptionId'],
  data () {
    return {
      showCategoryItemModal: false,
      title: '',
      description: '',
      basePrice: 0,
      toppingsCategory: '',
      toppingsTitle: '',
      toppingsBasePrice: '',
      toppingsEditId: '',
      isToppingsModalEditable: false,
      restaurantId: parseInt(localStorage.getItem('restaurantId')),
      categoryIdSelected: 0,
      itemIdSelected: 0,
      itemAccordingToCategory: [],
      editCategoryId: 0,
      editItemId: 0,
      assignOption: [
        {
          'identity': 'Kitchen',
          'name': 'Kitchen'
        },
        {
          'identity': 'Express',
          'name': 'Express'
        }
      ],
      assignSelected: '',
      terminalSelected: '',
      addFixedMealDeal: false,
      addCustomMealDeal: false,
      addGeneralMealDeal: false,
      printIdSelected: 0,
      statusSelected: '',
      sendStatusSelected: '',
      isSetDiffPrice: false,
      inPrice: '',
      inPriceG: '',
      outPrice: '',
      outPriceG: '',
      jwtToken: this.$cookies.get('token') || null,
      specialItem: false,
      shortId: null,
      latestShortId: null,
      customTopping: false,
      isAvailableDayClicked: false,
      availableDays: {
        all: null,
        sun: null,
        mon: null,
        tue: null,
        wed: null,
        thu: null,
        fri: null,
        sat: null
      },
      availableDaysArray: [],
      webToppingItems: ''
    }
  },
  mounted () {
    if (localStorage.getItem('itemNumber'+this.restaurantId)) {
      this.latestShortId = localStorage.getItem('itemNumber'+this.restaurantId)
    }
  },
  methods: {
    chooseItems () {
        this.itemAccordingToCategory = []
        for (var i = 0; i < this.itemDataList.length; i++) {
            if (this.itemDataList[i].categoryId == this.categoryIdSelected) {
                let itemObj = {id: this.itemDataList[i].id, label: this.itemDataList[i].label}

                this.itemAccordingToCategory.push(itemObj)
            }
        }
    },
    cancelCustomModal () {
        this.showCategoryItemModal = false

        // if user cancel the toppings.
        this.isToppingsModalEditable = false

        this.toppingsEditId = ''
        this.toppingsTitle = ''
        this.toppingsCategory = ''
        this.toppingsBasePrice = ''

        // if user cancel the category/item/selection
        this.title = ''
        this.description = ''
        this.basePrice = ''
        this.categoryIdSelected = 0
        this.itemIdSelected = 0

        this.editCategoryId = 0
        this.editItemId = 0

        this.statusSelected = ''
        this.sendStatusSelected = ''
        this.printIdSelected = ''
        this.assignSelected = ''
        this.terminalSelected = ''
    },
    editToppingsInfo () {
        let editableToppingsData = localStorage.getItem('editableToppingsData')

        editableToppingsData = JSON.parse(editableToppingsData)

        this.isToppingsModalEditable = true

        this.toppingsEditId = editableToppingsData.id
        this.toppingsTitle = editableToppingsData.title
        this.toppingsCategory = editableToppingsData.category
        this.toppingsBasePrice = editableToppingsData.price
    },
    categoryItemModal(general, fixed, custom, customTopping=false, create=false) {
      this.showCategoryItemModal = true
      window.scrollTo({ top: 0, behavior: "smooth"})
      this.addFixedMealDeal = fixed
      this.addCustomMealDeal = custom
      this.addGeneralMealDeal = general
      this.customTopping = customTopping
      if (create) {
        //select all days by default
        this.setOffDaysToAll()
      } else {
        //off days value reset
        this.resetOffDays()
      }
    },
    editCategoryInfo (val, title, description, basePrice, priceDifference, inPrice, outPrice, categoryId, itemId, general, fixed, custom, terminal, print, assign, status, daysOff, specialItem, shortId, webToppingItems) {
      this.itemAccordingToCategory = []
      this.editCategoryId = val
      this.title = title
      this.description = description
      this.basePrice = basePrice == '' ? 0 : basePrice
      this.categoryIdSelected = categoryId
      this.shortId = typeof shortId === 'undefined' ? null : shortId

      this.addFixedMealDeal = fixed
      this.addCustomMealDeal = custom
      this.addGeneralMealDeal = general

      this.isSetDiffPrice = priceDifference == 'Yes' ? true : false
      this.inPrice = inPrice
      this.outPrice = outPrice
      this.inPriceG = inPrice
      this.outPriceG = outPrice

      this.statusSelected = status
      this.sendStatusSelected = status
      this.printIdSelected = print
      this.assignSelected = assign
      this.terminalSelected = terminal
      this.specialItem = specialItem
      this.webToppingItems = webToppingItems

      // previously selected available days
      this.availableDaysArray = (typeof daysOff !== 'undefined') ? daysOff : []
      // set previously selected available days to the view
      this.setAvailableDays()

      if (itemId > 0) {
        for (var i = 0; i < this.itemDataList.length; i++) {
          if (this.itemDataList[i].categoryId == categoryId) {
            let itemObj = {id: this.itemDataList[i].id, label: this.itemDataList[i].label}

            this.itemAccordingToCategory.push(itemObj)
          }
        }

        setTimeout(() => {
          this.itemIdSelected = itemId
          this.editItemId = itemId
        }, 500)
      }
    },
    selctedCategory (val) {
        this.categoryIdSelected = val
    },
    createCategory () {
      let url = process.env.VUE_APP_API_URL+`/api/menu/create`
      let editUrl = process.env.VUE_APP_API_URL+`/api/menu/update`

      // request header
      let requestHeader = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.jwtToken}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers": "*",
          'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }

      // category
      if (this.isCategoryModal == true) {
          if (this.editCategoryId == 0) { //create
            let dataToSubmit = {
              // "id": 2,
              "restaurantId": this.restaurantId,
              "label": this.title,
              "resourceRef": "",
              "refCode": 0,
              "imgURL": "",
              "iconURL": "",
              "description": this.description,
              "categoryId": 0,
              "itemId": 0,
              "selectionId": 0,
              "toppingsId": 0,
              "modifierId": 0,
              "mealDealId": 0,
              "setId": 0,
              "printOptionId": this.printIdSelected,
              "printOptionTitle": "",
              "terminalOption": "",
              "status": this.sendStatusSelected,
              "basePrice": "",
              "toppingsCategory": "",
              "mealAssignFor": this.terminalSelected,
              "toppingsCategoryId": 0,
              "baseQty": 1,
              "minQty": 0,
              "maxQty": 0,
              "dataType": "Category",
              "mealDealType": "",
              "displayOrder": 2,
              "createdAt": "3 Sep 2020 11:05:30",
              "updatedAt": "30 Sep 2020 11:05:30",
              "children": [],
              "daysOff": this.availableDaysArray
            }

            axios
            .post(url, dataToSubmit,
            {
              headers: requestHeader
            })
            .then((data)=> {
              if (data.status == 200) {

                // this.showCategoryItemModal = true

                this.title = ''
                this.description = ''
                this.terminalSelected = ''
                this.sendStatusSelected = ''

                this.$toastr.s("Category created Successfully", "Success");
                this.$emit('categoryAndItem', data.data)

                setTimeout(() => {
                    this.resetValues()
                }, 500)
              }

              if (data.status === 403) {
                let logoutResponse = this.logout()
                if (logoutResponse === 1) {
                  this.$router.push({ path: '/login' })
                }
              }
            })
          }
          else { //edit
            let dataToSubmit = {
                "id": this.editCategoryId,
                "restaurantId": this.restaurantId,
                "label": this.title,
                "resourceRef": "",
                "refCode": 0,
                "imgURL": "",
                "iconURL": "",
                "description": this.description,
                "categoryId": 0,
                "itemId": 0,
                "selectionId": 0,
                "toppingsId": 0,
                "modifierId": 0,
                "mealDealId": 0,
                "setId": 0,
                "printOptionId": this.printIdSelected,
                "printOptionTitle": "",
                "terminalOption": "",
                "status": this.sendStatusSelected,
                "basePrice": "",
                "toppingsCategory": "",
                "mealAssignFor": this.terminalSelected,
                "toppingsCategoryId": 0,
                "baseQty": 1,
                "minQty": 0,
                "maxQty": 0,
                "dataType": "Category",
                "mealDealType": "",
                "displayOrder": 2,
                "createdAt": "3 Sep 2020 11:05:30",
                "updatedAt": "30 Sep 2020 11:05:30",
                "children": [],
                "daysOff": this.availableDaysArray
            }

            axios
            .post(editUrl, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
              // this.$emit("toppingsSubmitted")

              if (data.status == 200) {
                this.$toastr.s("Category edited Successfully", "Success");

                this.showCategoryItemModal = false

                this.title = ''
                this.description = ''

                this.$emit('categoryAndItem', data.data)

                setTimeout(() => {
                  this.resetValues()
                }, 500)
              }

              if (data.status === 403) {
                let logoutResponse = this.logout()
                if (logoutResponse === 1) {
                  this.$router.push({ path: '/login' })
                }
              }
            })
          }

      }

      // item
      if (this.isItemModal) {
        if (this.editCategoryId == 0) { //create
          let dataToSubmit = {
            // "id": 2,
            "restaurantId": this.restaurantId,
            "label": this.title,
            "resourceRef": "",
            "refCode": 0,
            "imgURL": "",
            "iconURL": "",
            "description": this.description,
            "categoryId": localStorage.getItem('selectedCategoryId'),
            "itemId": 0,
            "selectionId": 0,
            "toppingsId": 0,
            "modifierId": 0,
            "mealDealId": 0,
            "setId": 0,
            "printOptionId": this.printIdSelected,
            "printOptionTitle": "",
            "terminalOption": "",
            "status": this.statusSelected,
            "basePrice": (typeof this.basePrice !== 'undefined' && this.basePrice !== '') ? this.basePrice : 0,
            "toppingsCategory": "",
            "mealAssignFor": this.assignSelected,
            "toppingsCategoryId": 0,
            "baseQty": 1,
            "minQty": 0,
            "maxQty": 0,
            "dataType":  this.addGeneralMealDeal ? this.customTopping ? "CustomTopping" : "Item" : "MealDeal",
            "mealDealType": this.addGeneralMealDeal ? "" : this.addFixedMealDeal ? "Fixed" : "Custom",
            "displayOrder": 2,
            "createdAt": "3 Sep 2020 11:05:30",
            "updatedAt": "30 Sep 2020 11:05:30",
            "priceDifference": this.isSetDiffPrice === true ? "Yes" : "No",
            "inPrice": this.inPrice,
            "outPrice": this.outPrice,
            "children": [],
            "specialItem": this.specialItem === true ? 'Yes' : 'No',
            "shortId": (this.shortId === null || this.shortId === '') ? null : parseInt(this.shortId),
            "daysOff": this.availableDaysArray
          }

          axios
          .post(url, dataToSubmit,
          {
            headers: requestHeader
          })
          .then((data)=> {
            // this.$emit("toppingsSubmitted")
            if (data.status == 200) {
              // set item number for specific restaurant
              this.setLastItemNumber()
              // this.showCategoryItemModal = false
              this.title = ''
              this.shortId = null
              this.description = ''
              // this.printIdSelected = 0
              // this.statusSelected = ''
              // this.assignSelected = ''
              this.categoryIdSelected = 0
              this.isSetDiffPrice = false
              this.inPrice = this.outPrice = ''
              this.specialItem = false

              this.$toastr.s("Item created Successfully", "Success");
              this.$emit('categoryAndItem', data.data)

              setTimeout(() => {
                this.resetValues()
              }, 500)
            }

            if (data.status === 403) {
                let logoutResponse = this.logout()
                if (logoutResponse === 1) {
                    this.$router.push({ path: '/login' })
                }
            }
          })
          .catch ((error)=> {
              if (error.response.status === 400) {
                this.$toastr.Add({
                msg: 'Already assigned to ' + error.response.data.message, // Toast Message
                clickClose: false, // Click Close Disable
                timeout: 3000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
                type: "error", // Toast type,
                })
              }
            })
        }
        else { //edit
          let dataToSubmit = {
            "id": this.editCategoryId,
            "restaurantId": this.restaurantId,
            "label": this.title,
            "resourceRef": "",
            "refCode": 0,
            "imgURL": "",
            "iconURL": "",
            "description": this.description,
            "categoryId": localStorage.getItem('selectedCategoryId'),
            "itemId": 0,
            "selectionId": 0,
            "toppingsId": 0,
            "modifierId": 0,
            "mealDealId": 0,
            "setId": 0,
            "printOptionId": this.printIdSelected,
            "printOptionTitle": "",
            "terminalOption": "",
            "status": this.statusSelected,
            "basePrice": (typeof this.basePrice !== 'undefined' && this.basePrice !== '') ? parseFloat(this.basePrice) : 0,
            "toppingsCategory": "",
            "mealAssignFor": this.assignSelected,
            "toppingsCategoryId": 0,
            "baseQty": 1,
            "minQty": 0,
            "maxQty": 0,
            "dataType":  this.addGeneralMealDeal ? this.customTopping ? "CustomTopping" : "Item" : "MealDeal",
            "mealDealType": this.addGeneralMealDeal ? "" : this.addFixedMealDeal ? "Fixed" : "Custom",
            "displayOrder": 2,
            "createdAt": "3 Sep 2020 11:05:30",
            "updatedAt": "30 Sep 2020 11:05:30",
            "priceDifference": this.isSetDiffPrice === true ? "Yes" : "No",
            "inPrice": this.inPrice,
            "outPrice": this.outPrice,
            "children": [],
            "specialItem": this.specialItem === true ? 'Yes' : 'No',
            "shortId": (this.shortId === null || this.shortId === '') ? null : parseInt(this.shortId),
            "daysOff": this.availableDaysArray,
            "webToppingItems": this.webToppingItems
          }

          axios
          .post(editUrl, dataToSubmit,
          {
              headers: requestHeader
          })
          .then((data)=> {
            // this.$emit("toppingsSubmitted")
            if (data.status == 200) {
              this.$toastr.s("Item edited Successfully", "Success");

              this.showCategoryItemModal = false

              this.setLastItemNumber()

              this.title = ''
              this.shortId = null
              this.description = ''
              this.categoryIdSelected = 0
              this.isSetDiffPrice = false
              this.inPrice = this.outPrice = ''
              this.specialItem = false

              this.$emit('categoryAndItem', data.data)

              setTimeout(() => {
                this.resetValues()
              }, 500)
            }

            if (data.status === 403) {
              let logoutResponse = this.logout()
              if (logoutResponse === 1) {
                this.$router.push({ path: '/login' })
              }
            }
          })
          .catch ((error)=> {
            if (error.response.status === 400) {
              this.$toastr.Add({
              msg: 'Already assigned to ' + error.response.data.message, // Toast Message
              clickClose: false, // Click Close Disable
              timeout: 3000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
              type: "error", // Toast type,
              })
            }
          })
        }
      }

      // selection
      if (this.isSelectionModal) {
          if (this.editCategoryId == 0 && this.editItemId == 0) { //create
              let dataToSubmit = {
                  // "id": 2,
                  "restaurantId": this.restaurantId,
                  "label": this.title,
                  "resourceRef": "",
                  "refCode": 0,
                  "imgURL": "",
                  "iconURL": "",
                  "description": this.description,
                  "categoryId": localStorage.getItem('selectedCategoryId'),
                  "itemId": localStorage.getItem('selectedItemId'),
                  "selectionId": 0,
                  "toppingsId": 0,
                  "modifierId": 0,
                  "mealDealId": 0,
                  "setId": 0,
                  "printOptionId": this.printIdSelected,
                  "printOptionTitle": "",
                  "terminalOption": "",
                  "status": this.statusSelected,
                  "basePrice": (typeof this.basePrice !== 'undefined' && this.basePrice !== '') ? this.basePrice : 0,
                  "toppingsCategory": "",
                  "mealAssignFor": this.assignSelected,
                  "toppingsCategoryId": 0,
                  "baseQty": 1,
                  "minQty": 0,
                  "maxQty": 0,
                  "dataType": "Selection",
                  "mealDealType": "",
                  "displayOrder": 2,
                  "createdAt": "3 Sep 2020 11:05:30",
                  "updatedAt": "30 Sep 2020 11:05:30",
                  "priceDifference": this.isSetDiffPrice === true ? "Yes" : "No",
                  "inPrice": this.inPrice,
                  "outPrice": this.outPrice,
                  "children": [],
                  "specialItem": this.specialItem === true ? 'Yes' : 'No',
                  "shortId": (this.shortId === null || this.shortId === '') ? null : parseInt(this.shortId),
                  "daysOff": this.availableDaysArray
              }

              axios
              .post(url, dataToSubmit,
              {
                headers: requestHeader
              })
              .then((data)=> {
                // this.$emit("toppingsSubmitted")

                if (data.status == 200) {
                  this.$toastr.s("Selection created Successfully", "Success");

                  // this.showCategoryItemModal = false
                  this.setLastItemNumber()

                  this.title = ''
                  this.description = ''
                  this.shortId = null
                  // this.statusSelected = ''
                  // this.assignSelected = ''
                  this.categoryIdSelected = 0
                  this.isSetDiffPrice = false
                  this.inPrice = this.outPrice = ''
                  this.specialItem = false

                  this.$emit('categoryAndItem', data.data)

                  setTimeout(() => {
                    this.resetValues()
                  }, 500)
                }

                if (data.status === 403) {
                  let logoutResponse = this.logout()
                  if (logoutResponse === 1) {
                    this.$router.push({ path: '/login' })
                  }
                }
              })
              .catch ((error)=> {
                if (error.response.status === 400) {
                  this.$toastr.Add({
                  msg: 'Already assigned to ' + error.response.data.message, // Toast Message
                  clickClose: false, // Click Close Disable
                  timeout: 3000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
                  type: "error", // Toast type,
                  })
                }
              })
          }
          else { //edit
            let dataToSubmit = {
              "id": this.editCategoryId,
              "restaurantId": this.restaurantId,
              "label": this.title,
              "resourceRef": "",
              "refCode": 0,
              "imgURL": "",
              "iconURL": "",
              "description": this.description,
              "categoryId": this.categoryIdSelected,
              "itemId": this.itemIdSelected,
              "selectionId": 0,
              "toppingsId": 0,
              "modifierId": 0,
              "mealDealId": 0,
              "setId": 0,
              "printOptionId": this.printIdSelected,
              "printOptionTitle": "",
              "terminalOption": "",
              "status": this.statusSelected,
              "basePrice": (typeof this.basePrice !== 'undefined' && this.basePrice !== '') ? this.basePrice : 0,
              "toppingsCategory": "",
              "mealAssignFor": this.assignSelected,
              "toppingsCategoryId": 0,
              "baseQty": 1,
              "minQty": 0,
              "maxQty": 0,
              "dataType": "Selection",
              "mealDealType": "",
              "displayOrder": 2,
              "createdAt": "3 Sep 2020 11:05:30",
              "updatedAt": "30 Sep 2020 11:05:30",
              "priceDifference": this.isSetDiffPrice === true ? "Yes" : "No",
              "inPrice": this.inPrice,
              "outPrice": this.outPrice,
              "children": [],
              "specialItem": this.specialItem === true ? 'Yes' : 'No',
              "shortId": (this.shortId === null || this.shortId === '') ? null : parseInt(this.shortId),
              "daysOff": this.availableDaysArray
            }

            axios
            .post(editUrl, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
              // this.$emit("toppingsSubmitted")

              if (data.status == 200) {
                this.$toastr.s("Selection edited Successfully", "Success");

                this.showCategoryItemModal = false

                this.setLastItemNumber()

                this.title = ''
                this.description = ''
                this.shortId = null
                this.categoryIdSelected = 0
                this.isSetDiffPrice = false
                this.inPrice = this.outPrice = ''
                this.specialItem = false

                this.$emit('categoryAndItem', data.data)

                setTimeout(() => {
                  this.resetValues()
                }, 500)
              }

              if (data.status === 403) {
                let logoutResponse = this.logout()
                if (logoutResponse === 1) {
                  this.$router.push({ path: '/login' })
                }
              }
            })
            .catch ((error)=> {
              if (error.response.status === 400) {
                this.$toastr.Add({
                msg: 'Already assigned to ' + error.response.data.message, // Toast Message
                clickClose: false, // Click Close Disable
                timeout: 3000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
                type: "error", // Toast type,
                })
              }
            })
          }
      }
    },
    resetValues () {
        this.itemAccordingToCategory = []

        this.editCategoryId = 0
        this.editItemId = 0
        this.title = ''
        this.description = ''
        this.basePrice = 0
        this.categoryIdSelected = 0
        this.itemIdSelected = 0
    },
    createToppings () {

        let url = process.env.VUE_APP_API_URL+`/api/topping/create`
        let editUrl = process.env.VUE_APP_API_URL+`/api/topping/update`

        // request header
        let requestHeader = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.jwtToken}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Headers": "*",
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }

        if (this.isToppingsModalEditable) {
            let dataToSubmit = {
                "id": this.toppingsEditId,
                "restaurantId": this.restaurantId,
                "label": this.toppingsTitle,
                "resourceRef": "toppings-1",
                "refCode": 3000,
                "imgURL": "",
                "iconURL": "",
                "description": "",
                "categoryId": 0,
                "itemId": 0,
                "selectionId": 0,
                "toppingsId": 3000,
                "modifierId": 0,
                "mealDealId": 0,
                "setId": 0,
                "printOptionId": 1,
                "printOptionTitle": "",
                "terminalOption": "",
                "status": "Active",
                "basePrice": (typeof this.toppingsBasePrice !== 'undefined' && this.toppingsBasePrice !== '') ? this.toppingsBasePrice : 0,
                "toppingsCategory": this.toppingsCategory,
                "mealAssignFor": "",
                // "toppingsCategoryId": 1,
                "baseQty": 1,
                "minQty": 0,
                "maxQty": 0,
                "dataType": "Toppings",
                "mealDealType": "",
                "displayOrder": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "updatedAt": "30 Sep 2020 11:05:30",
                "children": []
            }

            axios
            .post(editUrl, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                this.$emit("toppingsSubmitted")

                if (data.status == 200) {
                    this.$toastr.s("Created Successfully", "Success");

                    this.showCategoryItemModal = false

                    this.isToppingsModalEditable = false

                    this.toppingsEditId = ''
                    this.toppingsTitle = ''
                    this.toppingsCategory = ''
                    this.toppingsBasePrice = ''

                    localStorage.removeItem('editableToppingsData')
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }
            })
        }
        else {
            let dataToSubmit = {
                // "id": this.toppingsEditId,
                "restaurantId": this.restaurantId,
                "label": this.toppingsTitle,
                "resourceRef": "toppings-1",
                "refCode": 3000,
                "imgURL": "",
                "iconURL": "",
                "description": "",
                "categoryId": 0,
                "itemId": 0,
                "selectionId": 0,
                "toppingsId": 3000,
                "modifierId": 0,
                "mealDealId": 0,
                "setId": 0,
                "printOptionId": 1,
                "printOptionTitle": "",
                "terminalOption": "",
                "status": "Active",
                "basePrice": (typeof this.toppingsBasePrice !== 'undefined' && this.toppingsBasePrice !== '') ? this.toppingsBasePrice : 0,
                "toppingsCategory": this.toppingsCategory,
                "mealAssignFor": "",
                "toppingsCategoryId": 1,
                "baseQty": 1,
                "minQty": 0,
                "maxQty": 0,
                "dataType": "Toppings",
                "mealDealType": "",
                "displayOrder": 1,
                "createdAt": "3 Sep 2020 11:05:30",
                "updatedAt": "30 Sep 2020 11:05:30",
                "children": []
            }

            axios
            .post(url, dataToSubmit,
            {
                headers: requestHeader
            })
            .then((data)=> {
                this.$emit("toppingsSubmitted")

                if (data.status == 200) {
                    this.$toastr.s("Created Successfully", "Success");

                    this.showCategoryItemModal = false

                    this.toppingsEditId = ''
                    this.toppingsTitle = ''
                    this.toppingsCategory = ''
                    this.toppingsBasePrice = ''
                }

                if (data.status === 403) {
                    let logoutResponse = this.logout()
                    if (logoutResponse === 1) {
                        this.$router.push({ path: '/login' })
                    }
                }
            })
        }
    },
    setDiffPrice() {
      this.isSetDiffPrice = !this.isSetDiffPrice
      if (this.isSetDiffPrice === false) {
          this.inPrice = this.outPrice = '';
      }else{
          this.inPrice = this.inPriceG
          this.outPrice = this.outPriceG;
      }
    },
    setLastItemNumber () {
      if (this.shortId) {
        localStorage.setItem('itemNumber'+this.restaurantId, this.shortId)
        this.latestShortId = this.shortId
      }
    },
    toggleDayList () {
      this.isAvailableDayClicked = !this.isAvailableDayClicked
    },
    selectAvailableDay (day) {
      if (this.availableDays.all && day != 'All') {
        this.availableDays.all= false
        this.availableDaysArray = []
        if (this.availableDays.sun) { this.availableDaysArray.push('Sun') }
        if (this.availableDays.mon) { this.availableDaysArray.push('Mon') }
        if (this.availableDays.tue) { this.availableDaysArray.push('Tue') }
        if (this.availableDays.wed) { this.availableDaysArray.push('Wed') }
        if (this.availableDays.thu) { this.availableDaysArray.push('Thu') }
        if (this.availableDays.fri) { this.availableDaysArray.push('Fri') }
        if (this.availableDays.sat) { this.availableDaysArray.push('Sat') }
        this.availableDaysArray = this.availableDaysArray.filter(d => d !== day)
      } else {
        if (this.availableDaysArray.includes(day)) {
          this.availableDaysArray = this.availableDaysArray.filter(d => d !== day)
          if (day == 'All') {
            this.availableDays.all= false
            this.availableDays.sun= false
            this.availableDays.mon= false
            this.availableDays.tue= false
            this.availableDays.wed= false
            this.availableDays.thu= false
            this.availableDays.fri= false
            this.availableDays.sat= false
            this.availableDaysArray = []
          }
        } else {
          if (day == 'All') {
            this.availableDays.all= true
            this.availableDays.sun= true
            this.availableDays.mon= true
            this.availableDays.tue= true
            this.availableDays.wed= true
            this.availableDays.thu= true
            this.availableDays.fri= true
            this.availableDays.sat= true

            this.availableDaysArray = ['All']
          } else {
            this.availableDaysArray.push(day)
          }
        }
      }
    },
    setAvailableDays () {
      //off days value reset
      this.isAvailableDayClicked = false
      this.availableDays.all = null
      this.availableDays.sun = null
      this.availableDays.mon = null
      this.availableDays.tue = null
      this.availableDays.wed = null
      this.availableDays.thu = null
      this.availableDays.fri = null
      this.availableDays.sat = null

      this.availableDaysArray.forEach(day => {
        if (day == 'All') {
          this.availableDays.all= true
          this.availableDays.sun= true
          this.availableDays.mon= true
          this.availableDays.tue= true
          this.availableDays.wed= true
          this.availableDays.thu= true
          this.availableDays.fri= true
          this.availableDays.sat= true
        } else {
          if (day == 'Sun') { this.availableDays.sun = true }
          if (day == 'Mon') { this.availableDays.mon = true }
          if (day == 'Tue') { this.availableDays.tue = true }
          if (day == 'Wed') { this.availableDays.wed = true }
          if (day == 'Thu') { this.availableDays.thu = true }
          if (day == 'Fri') { this.availableDays.fri = true }
          if (day == 'Sat') { this.availableDays.sat = true }
        }
      })
    },
    resetOffDays () {
      this.isAvailableDayClicked = false
      this.availableDays.all = null
      this.availableDays.sun = null
      this.availableDays.mon = null
      this.availableDays.tue = null
      this.availableDays.wed = null
      this.availableDays.thu = null
      this.availableDays.fri = null
      this.availableDays.sat = null
      this.availableDaysArray = []
    },
    setOffDaysToAll () {
      this.isAvailableDayClicked = false
      this.availableDays.all = true
      this.availableDays.sun = true
      this.availableDays.mon = true
      this.availableDays.tue = true
      this.availableDays.wed = true
      this.availableDays.thu = true
      this.availableDays.fri = true
      this.availableDays.sat = true
      this.availableDaysArray = ["All"]
    }
  },
  watch: {
    selectedItemPrintOptionId (selectedItemPrintOptionId) {
      this.printIdSelected = selectedItemPrintOptionId
    }
  }
}
</script>

<style >
.modal-vue .overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
}

.modal-vue .modal-own {
    position: absolute;
    width: 500px;
    z-index: 9999;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 10px;
    top: 5%;
    left: 30%;
}

.modal-vue .close{
    position: absolute;
    top: 10px;
    right: 10px;
}

h5 {
    color: #FE7611 !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}

.submitBtn {
    background-color: #E317E3;
    color: white;
    border-color:  #E317E3;
}

.cancelBtn {
    background-color: #797979;
    color: white;
    border-color:  #797979;
}
.availableDays .dropdown-menu{
  opacity: 1;
  bottom: 100%;
  top: unset !important;
  line-height: 2rem;
  padding: 1rem 1.5rem;
  min-width: 115px !important;
  text-align: right;
}
.availableDays input{margin-left: .5rem; width: 15px; height: 15px;}
</style>
