<template>
  <div>
    <h2 class="text-center mt-0 pt-4" style="border-bottom: 2px solid;">Colors</h2>
    <div class="row pl-5 w-100">
      <div class="col-md-3">
        <div class="row">
          <div class="col-md-12">
            <label for="">Header Bg Color</label>
            <input type="text" class="form-control" v-model="headerColor" placeholder="#FFFFFF">
          </div>
          <div class="col-md-12 mt-3">
            <label for="">Header Text Color</label>
            <input type="text" class="form-control" v-model="navigationColor" placeholder="#FFFFFF">
          </div>
          <div class="col-md-12 mt-3">
            <label for="">Header Hover Text Color</label>
            <input type="text" class="form-control" v-model="navbarHoverColor" placeholder="#FFFFFF">
          </div>
          <div class="col-md-12 mt-3">
            <label for="">Menu Icon Bg Color</label>
            <input type="text" class="form-control" v-model="menuIconBgColor" placeholder="#FFFFFF">
          </div>
          <!-- <div class="col-md-12">
            <label for="">Mobile Navbar Background Color</label>
            <input type="text" class="form-control" v-model="mobileNavBgColor" placeholder="Mobile Navbar Background Color">
          </div> -->
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <div class="col-md-12">
            <label for="">Footer Bg Color</label>
            <input type="text" class="form-control" v-model="footerColor" placeholder="#FFFFFF">
          </div>
          <div class="col-md-12 mt-3">
            <label for="">Footer Text Color</label>
            <input type="text" class="form-control" v-model="footerTextColor" placeholder="#FFFFFF">
          </div>
          <div class="col-md-12 mt-3">
            <label for="">All Rights Reserved Text Color</label>
            <input type="text" class="form-control" v-model="rightsReservedTextColor" placeholder="All rights reserved text color">
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <div class="col-md-12">
            <label for="">Button Background Color</label>
            <input type="text" class="form-control" v-model="buttonColor" placeholder="#FFFFFF">
          </div>
          <div class="col-md-12 mt-3">
            <label for="">Button Text Color</label>
            <input type="text" class="form-control" v-model="buttonFontColor" placeholder="#FFFFFF">
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <div class="col-md-12">
            <label for="">Left Category Hover Bg Color</label>
            <input type="text" class="form-control" v-model="categoryHoverBgColor" placeholder="#FFFFFF">
          </div>
          <div class="col-md-12 mt-3">
            <label for="">Left Category Hover Text Color</label>
            <input type="text" class="form-control" v-model="categoryHoverTextColor" placeholder="#FFFFFF">
          </div>
          <div class="col-md-12 mt-3">
            <label for="">Middle Category Bg Color</label>
            <input type="text" class="form-control" v-model="categoryBgForDesktop" placeholder="#FFFFFF">
          </div>
        </div>
      </div>
    </div>

    <hr style="background-color: red; padding: 1px">
    <div class="row pl-5 w-100">
      <div class="col-md-3 mt-3">
        <label for="">Alert Box Background Color</label>
        <input type="text" class="form-control" v-model="alertBgColor" placeholder="#FFFFFF">
      </div>
      <div class="col-md-3 mt-3">
        <label for="">Alert Text Color</label>
        <input type="text" class="form-control" v-model="alertTextColor" placeholder="#FFFFFF">
      </div>
      <div class="col-md-3 mt-3">
        <label for="">Header Two Background</label>
        <input type="text" class="form-control" v-model="headerTwoBackgroundColor" placeholder="#FFFFFF">
      </div>
      <!-- <div class="col-md-3 mt-3">
        <label for="">Mission Background Color</label>
        <input type="text" class="form-control" v-model="aboutBackgroundColor" placeholder="#FFFFFF">
      </div> -->
      <div class="col-md-3 mt-3">
        <label for="">Review Background Color</label>
        <input type="text" class="form-control" v-model="reviewBackgroundColor" placeholder="#FFFFFF">
      </div>
      <div class="col-md-3 mt-3">
        <label for="">Review Text Color</label>
        <input type="text" class="form-control" v-model="reviewTextColor" placeholder="Review Text color">
      </div>
      <div class="col-md-3 mt-3">
        <label for="">Cart Item Bg Color</label>
        <input type="text" class="form-control" v-model="cartItemBgColor" placeholder="#FFFFFF">
      </div>
      <div class="col-md-3 mt-3">
        <label for="">Contact Page Background Color</label>
        <input type="text" class="form-control" v-model="contactPageBgColor" placeholder="#FFFFFF">
      </div>

      <!-- <div class="col-md-3 mt-3">
        <label for="">Alert Button Background Color</label>
        <input type="text" class="form-control" v-model="alertBoxBtnBgColor" placeholder="Alert button background color">
      </div>
      <div class="col-md-3 mt-3">
        <label for="">Alert Button Font Color</label>
        <input type="text" class="form-control" v-model="alertBoxBtnFontColor" placeholder="Alert button font color">
      </div>
      <div class="col-md-3 mt-3">
        <label for="">Welcome Text</label>
        <input type="text" class="form-control" v-model="welcomeTextColor" placeholder="Welcome text color">
      </div> -->
      <!-- <div class="col-md-3 mt-3">
        <label for="">Welcome Text Background</label>
        <input type="text" class="form-control" v-model="welcomeBackgroundColor" placeholder="Welcome text background color">
      </div> -->
      <!-- <div class="col-md-3 mt-3">
        <label for="">Mission Text Color</label>
        <input type="text" class="form-control" v-model="aboutTextColor" placeholder="Mission text color">
      </div> -->
      <!-- <div class="col-md-3 mt-3">
        <label for="">Mission Background Color</label>
        <input type="text" class="form-control" v-model="aboutBackgroundColor" placeholder="Mission background color">
      </div> -->
      <!--
      <div class="col-md-3 mt-3">
        <label for="">Footer Powered by</label>
        <input type="text" class="form-control" v-model="footerPoweredByColor" placeholder="Powered By color">
      </div> -->
      <!-- <div class="col-md-3 mt-3">
        <label for="">Category Bg For Mobile</label>
        <input type="text" class="form-control" v-model="categoryBgForMobile" placeholder="Category bg for mobile">
      </div> -->

    </div>
    <div class="col-md-12 p-5 contentBtnAlign">
      <button class="btn submitBtn" @click="updateWebConfig">Upload</button>
      <img v-if="showWebConfigLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: [
    'webConfig',
    'requestHeader',
  ],
  data() {
    return {
      headerColor: '',
      navigationColor: '',
      navbarHoverColor: '',
      menuIconBgColor: '',
      mobileNavBgColor: '',
      footerColor: '',
      footerTextColor: '',
      rightsReservedTextColor: '',
      footerPoweredByColor: '',
      categoryBgForDesktop: '',
      categoryBgForMobile: '',
      categoryHoverBgColor: '',
      categoryHoverTextColor: '',
      buttonColor: '',
      buttonFontColor: '',
      alertBgColor: '',
      alertTextColor: '',
      headerTwoBackgroundColor: '',
      reviewBackgroundColor: '',
      reviewTextColor: '',
      cartItemBgColor: '',
      contactPageBgColor: '',
      aboutTextColor: '',
      showWebConfigLoading: false,
    }
  },
  mounted() {
    this.headerColor = this.webConfig.headerColor
    this.navigationColor = this.webConfig.navigationColor
    this.navbarHoverColor = this.webConfig.navbarHoverColor
    this.menuIconBgColor = this.webConfig.menuIconBgColor
    this.mobileNavBgColor = this.webConfig.mobileNavBgColor
    this.footerColor = this.webConfig.footerColor
    this.footerTextColor = this.webConfig.footerTextColor
    this.rightsReservedTextColor = this.webConfig.rightsReservedTextColor
    this.footerPoweredByColor = this.webConfig.footerPoweredByColor
    this.categoryBgForDesktop = this.webConfig.categoryBgForDesktop
    this.categoryBgForMobile = this.webConfig.categoryBgForMobile
    this.categoryHoverBgColor = this.webConfig.categoryHoverBgColor
    this.categoryHoverTextColor = this.webConfig.categoryHoverTextColor
    this.buttonColor = this.webConfig.buttonColor
    this.buttonFontColor = this.webConfig.buttonFontColor
    this.alertBgColor = this.webConfig.alertBgColor
    this.alertTextColor = this.webConfig.alertTextColor
    this.headerTwoBackgroundColor = this.webConfig.headerTwoBackgroundColor
    this.reviewBackgroundColor = this.webConfig.reviewBackgroundColor
    this.reviewTextColor = this.webConfig.reviewTextColor
    this.cartItemBgColor = this.webConfig.cartItemBgColor
    this.contactPageBgColor = this.webConfig.contactPageBgColor
    this.aboutTextColor = this.webConfig.aboutTextColor
  },
  methods: {
    updateWebConfig(){
      this.showWebConfigLoading = true

      // request data
      let dataToSubmit = {
        "restaurantId": localStorage.getItem('restaurantId'),
        "welcomeTxt": this.webConfig.welcomeTxt,
        "aboutUs": this.webConfig.aboutUs,
        "privacyPolicy": this.webConfig.privacyPolicy,
        "termsCondition": this.webConfig.termsCondition,
        "lat": this.webConfig.lat,
        "long": this.webConfig.long,
        "mapIframe": this.webConfig.mapIframe,
        "stripeSecretKey": this.webConfig.stripeSecretKey,
        "stripePublishKey": this.webConfig.stripePublishKey,
        "elavonUserName": this.webConfig.elavonUserName,
        "elavonPassword": this.webConfig.elavonPassword,
        "defaultPaymentGateway": this.webConfig.defaultPaymentGateway,
        "headerColor": this.headerColor,
        "navigationColor": this.navigationColor,
        "navbarHoverColor": this.navbarHoverColor,
        "menuIconBgColor": this.menuIconBgColor,
        "mobileNavBgColor": this.mobileNavBgColor,
        "alertBgColor": this.alertBgColor,
        "alertTextColor": this.alertTextColor,
        "alertBoxBtnBgColor": this.webConfig.alertBoxBtnBgColor,
        "alertBoxBtnFontColor": this.webConfig.alertBoxBtnFontColor,
        "welcomeTextColor": this.webConfig.welcomeTextColor,
        "welcomeBackgroundColor": this.webConfig.welcomeBackgroundColor,
        "welcomeBgShadowColor": this.webConfig.welcomeBgShadowColor,
        "sliderText": this.webConfig.sliderText,
        "headerTwoBackgroundColor": this.headerTwoBackgroundColor,
        "aboutTextColor": this.webConfig.aboutTextColor,
        "aboutBackgroundColor": this.webConfig.aboutBackgroundColor,
        "reviewTextColor": this.reviewTextColor,
        "reviewFontColor": this.webConfig.reviewFontColor,
        "reviewBackgroundColor": this.reviewBackgroundColor,
        "footerColor": this.footerColor,
        "footerTextColor": this.footerTextColor,
        "rightsReservedTextColor": this.rightsReservedTextColor,
        "poweredByColor": this.footerPoweredByColor,
        "categoryBgForDesktop": this.categoryBgForDesktop,
        "categoryBgForMobile": this.categoryBgForMobile,
        "categoryHoverBgColor": this.categoryHoverBgColor,
        "categoryHoverTextColor": this.categoryHoverTextColor,
        "cartItemBgColor": this.cartItemBgColor,
        "buttonColor": this.buttonColor,
        "buttonFontColor": this.buttonFontColor,
        "contactPageBgColor": this.contactPageBgColor,
        "openingTime": this.webConfig.openingTime,
        "closingTime": this.webConfig.closingTime,
        // "timeSlotApplicable": this.timeSlotApplicable,
        "allergyInformation": this.webConfig.allergyInformation,
        "lunchOpeningHours": this.webConfig.lunchOpeningHours,
        "socialMedia": this.webConfig.socialMedia,
        "webAddress": this.webConfig.webAddress
      }

      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/update'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showWebConfigLoading = false
            this.$toastr.s("Successfully Updated.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
  }
}
</script>
