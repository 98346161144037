<template>
  <div>
    <h2 class="text-center mt-0 pt-4" style="border-bottom: 2px solid;">Menu PDF</h2>

    <div class="row">
      <div class="col-md-4 text-center my-2">
        <img :src="menuPdf" width="200" alt="File" class="header-image">
        <i class="fa fa-times position-absolute header-delete"
            @click="() => deleteImage(menuPdfPublicId, 'MenuPdf')" aria-hidden="true"></i>
      </div>
      <div class="col-md-12">
        <form @submit.prevent="submitMenu">
          <div class="row">
            <div class="col-md-4 text-center p-4">
              <img src="/img/upload.png" alt=""> Upload New Menu
              <input type="file" @change="menuPdfSelected" class="uploadBtn"/>
            </div>
            <div class="col-md-4 text-left p-4 contentBtnAlign">
              <button class="btn submitBtn">Upload</button>
              <img v-if="showMenuLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: [
    'menu',
    'menuPdfPublicId',
    'requestHeader',
  ],
  data() {
    return {
      menuPdf: '',
      showMenuLoading: false,
    }
  },
  mounted() {
    // this.menuPdf = this.menu
  },
  methods: {
    menuPdfSelected(e){
      this.menuPdf = e.target.files[0]
    },
    submitMenu() {
      if (!this.menuPdf) {
        this.$toastr.w("Please select file.", "Warning");
        return
      }
      this.showMenuLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.menuPdf || '')
      dataToSubmit.append('imageType', 'MenuPdf')
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showMenuLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    deleteImage(publicId, headerType){
      if (confirm('Please confirm to delete.')){
        // request url
        let url = process.env.VUE_APP_API_URL+'/api/webConfig/v2/deleteImage'
        // request data
        let dataToSubmit = {
          "restaurantId": parseInt(localStorage.getItem('restaurantId')),
          "publicId": publicId,
          "imageType": headerType
        }
        // send request
        axios
          .post(url, dataToSubmit, {
            headers: this.requestHeader
          })
          .then((data) => {
            if (data.status == 200) {
              this.$toastr.s("Successfully Deleted.", "Success");
              setTimeout(()=> {this.$router.go()}, 1000)
            }
          })
      }
    },
  }
}
</script>
<style scoped>
.uploadBtn {
  cursor: pointer;
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.submitBtn {
  background-color: #E317E3;
  color: white;
  border-color:  #E317E3;
}
.header-delete{width: 40px; height:40px; top: 0; cursor:pointer; font-size: 1.5rem;right: 35px; color: red;}

</style>
