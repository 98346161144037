<template>
  <div>
    <h2 class="text-center mt-0 pt-4" style="border-bottom: 2px solid;">Image</h2>
    <!-- favicon -->
    <div style="background: rgb(209 201 218)">
      <h3 class="pl-3">Favicon</h3>
      <div class="row">
        <div class="col-md-4 text-center my-2">
          <img :src="favicon" width="200" alt="Image" class="header-image">
          <i class="fa fa-times position-absolute header-delete"
            @click="() => deleteImage(favIconPublicId, imageTypeFavicon)" aria-hidden="true"></i>
        </div>
        <div class="col-md-12">
          <form @submit.prevent="submitFavicon">
            <div class="row">
              <div class="col-md-4 text-center p-4">
                <img src="/img/upload.png" alt=""> Upload New Image
                <input type="file" @change="faviconSelected" accept="image/*" class="uploadBtn"/>
              </div>
              <div class="col-md-4 text-left p-4 contentBtnAlign">
                <button class="btn submitBtn">Upload</button>
                <img v-if="showFaviconLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- logo -->
    <div style="background: rgb(209 201 218)">
      <h3 class="pl-3">Logo</h3>
      <div class="row">
        <div class="col-md-4 text-center my-2">
          <img :src="companyLogo" width="200" alt="Image" class="header-image">
          <i class="fa fa-times position-absolute header-delete"
            @click="() => deleteImage(logoPublicId, imageTypeLogo)" aria-hidden="true"></i>
        </div>
        <div class="col-md-12">
          <form @submit.prevent="submitLogo">
            <div class="row">
              <div class="col-md-4 text-center p-4">
                <img src="/img/upload.png" alt=""> Upload New Image
                <input type="file" @change="logoSelected" accept="image/*" class="uploadBtn"/>
              </div>
              <div class="col-md-4 text-left p-4 contentBtnAlign">
                <button class="btn submitBtn">Upload</button>
                <img v-if="showLogoLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- background image -->
    <div style="background: rgb(209 201 218)">
      <h3 class="pl-3">Background Image</h3>
      <div class="row">
        <div class="col-md-4 text-center my-2">
          <img :src="backgroundImage" width="300" alt="Image" class="header-image">
          <i class="fa fa-times position-absolute header-delete"
            @click="() => deleteImage(backgroundImagePublicId, imageTypeBackground)" aria-hidden="true"></i>
        </div>
        <div class="col-md-12">
          <form @submit.prevent="submitBackgroundImage">
            <div class="row">
              <div class="col-md-4 text-center p-4">
                <img src="/img/upload.png" alt=""> Upload New Image
                <input type="file" @change="backgroundImageSelected" accept="image/*" class="uploadBtn"/>
              </div>
              <div class="col-md-4 text-left p-4 contentBtnAlign">
                <button class="btn submitBtn">Upload</button>
                <img v-if="showBackgroundImageLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--header one-->
    <div style="background: rgb(209 201 218)">
      <h3 class="pl-3">Header One</h3>
      <div class="row">
        <div v-for="(item,i) in headerOneImages"
            :key="i"
            class="col-md-4 text-center my-2">
          <img :src="item.src" width="300" alt="Image" class="header-image">
          <i class="fa fa-times position-absolute header-delete"
            @click="() => deleteImage(item.publicId, headerTypeOne)" aria-hidden="true"></i>
        </div>
        <div class="col-md-12">
          <form @submit.prevent="submitHeaderOne">
            <div class="row">
              <div class="col-md-4 text-center p-4">
                <img src="/img/upload.png" alt=""> Upload New Image
                <input type="file" @change="headerOneImageSelected" accept="image/*" class="uploadBtn"/>
              </div>
              <div class="col-md-4 text-left p-4 contentBtnAlign">
                <button class="btn submitBtn">Upload</button>
                <img v-if="showHeaderOneLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--header two-->
    <div style="background: rgb(214 243 219)">
      <h3 class="pl-3 mt-4">Header Two</h3>
      <div class="row">
        <div v-for="(item,i) in headerTwoImages"
            :key="i"
            class="col-md-4 text-center my-2">
          <img :src="item.src" width="300" alt="Image" class="header-image">
          <i class="fa fa-times position-absolute header-delete"
            @click="() => deleteImage(item.publicId, headerTypeTwo)" aria-hidden="true"></i>
        </div>
        <div class="col-md-12">
          <form @submit.prevent="submitHeaderTwo">
            <div class="row">
              <div class="col-md-4 text-center p-4">
                <img src="/img/upload.png" alt=""> Upload New Image
                <input type="file" @change="headerTwoImageSelected" accept="image/*" class="uploadBtn"/>
              </div>
              <div class="col-md-4 text-left p-4 contentBtnAlign">
                <button class="btn submitBtn">Upload</button>
                <img v-if="showHeaderTwoLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--header three-->
    <div style="background: rgb(224 208 222)">
      <h3 class="pl-3 mt-4">Header Three</h3>
      <div class="row px-2">
      <div v-for="(item,i) in headerThreeImages"
          :key="i"
          class="col-md-3 text-center my-2">
        <img :src="item.src" width="100%" alt="Image" class="header-image">
        <i class="fa fa-times position-absolute header-delete text-right"
          @click="() => deleteImage(item.publicId, headerTypeThree)" aria-hidden="true"></i>
      </div>
      <div class="col-md-12">
        <form @submit.prevent="submitHeaderThree">
          <div class="row">
            <div class="col-md-4 text-center p-4">
              <img src="/img/upload.png" alt=""> Upload New Image
              <input type="file" @change="headerThreeImageSelected" accept="image/*" class="uploadBtn"/>
            </div>
            <div class="col-md-4 text-left p-4 contentBtnAlign">
              <button class="btn submitBtn">Upload</button>
              <img v-if="showHeaderThreeLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
            </div>
          </div>
        </form>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: [
    'icon',
    'favIconPublicId',
    'logo',
    'logoPublicId',
    'bgImage',
    'backgroundImagePublicId',
    'headOneImgs',
    'headTwoImgs',
    'headThreeImgs',
    'requestHeader',
  ],
  data() {
    return {
      favicon: '',
      imageTypeFavicon: 'FavIcon',
      showFaviconLoading: false,

      companyLogo: '',
      imageTypeLogo: 'Logo',
      showLogoLoading: false,

      backgroundImage: '',
      imageTypeBackground: 'BackgroundImage',
      showBackgroundImageLoading: false,

      headerOne: {
        image: null,
      },
      headerOneImages: [],
      headerTypeOne: 'HeaderOne',
      showHeaderOneLoading: false,

      headerTwo: {
        image: null,
      },
      headerTwoImages: [],
      headerTypeTwo: 'HeaderTwo',
      showHeaderTwoLoading: false,

      headerThree: {
        image: null,
      },
      headerThreeImages: [],
      headerTypeThree: 'HeaderThree',
      showHeaderThreeLoading: false,



    }
  },
  mounted() {
    this.favicon = this.icon
    this.companyLogo = this.logo
    this.backgroundImage = this.bgImage
    this.headerOneImages = this.headOneImgs
    this.headerTwoImages = this.headTwoImgs
    this.headerThreeImages = this.headThreeImgs
  },
  methods: {
    faviconSelected(e){
      this.favicon = e.target.files[0]
    },
    logoSelected(e){
      this.companyLogo = e.target.files[0]
    },
    backgroundImageSelected(e){
      this.backgroundImage = e.target.files[0]
    },
    headerOneImageSelected(e){
      this.headerOne.image = e.target.files[0]
    },
    headerTwoImageSelected(e){
      this.headerTwo.image = e.target.files[0]
    },
    headerThreeImageSelected(e){
      this.headerThree.image = e.target.files[0]
    },

    submitFavicon() {
      this.showFaviconLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.favicon || '')
      dataToSubmit.append('imageType', this.imageTypeFavicon)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')

      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showFaviconLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitLogo() {
      this.showLogoLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.companyLogo || '')
      dataToSubmit.append('imageType', this.imageTypeLogo)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showLogoLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitBackgroundImage() {
      this.showBackgroundImageLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.backgroundImage || '')
      dataToSubmit.append('imageType', this.imageTypeBackground)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showBackgroundImageLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitHeaderOne() {
      this.showHeaderOneLoading = true

      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.headerOne.image || '')
      dataToSubmit.append('imageType', this.headerTypeOne)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showHeaderOneLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitHeaderTwo() {
      this.showHeaderTwoLoading = true
      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.headerTwo.image || '')
      dataToSubmit.append('imageType', this.headerTypeTwo)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showHeaderTwoLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    submitHeaderThree() {
      this.showHeaderThreeLoading = true
      const dataToSubmit = new FormData()
      dataToSubmit.append('file', this.headerThree.image || '')
      dataToSubmit.append('imageType', this.headerTypeThree)
      dataToSubmit.append('restaurantId', localStorage.getItem('restaurantId') || '')
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/upload'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showHeaderThreeLoading = false
            this.$toastr.s("Successfully Created.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
    deleteImage(publicId, headerType){
      if (confirm('Please confirm to delete.')){
        // request url
        let url = process.env.VUE_APP_API_URL+'/api/webConfig/v2/deleteImage'
        // request data
        let dataToSubmit = {
          "restaurantId": parseInt(localStorage.getItem('restaurantId')),
          "publicId": publicId,
          "imageType": headerType
        }
        // send request
        axios
          .post(url, dataToSubmit, {
            headers: this.requestHeader
          })
          .then((data) => {
            if (data.status == 200) {
              this.$toastr.s("Successfully Deleted.", "Success");
              setTimeout(()=> {this.$router.go()}, 1000)
            }
          })
      }
    },
  }
}
</script>

<style scoped>
.uploadBtn {
  cursor: pointer;
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.submitBtn {
  background-color: #E317E3;
  color: white;
  border-color:  #E317E3;
}
.header-delete{width: 40px; height:40px; top: 0; cursor:pointer; font-size: 1.5rem;right: 35px; color: red;}

</style>
