<template>

  <div class="content">
    <div class="container" style="padding-left: 10px; overflow-x: hidden">
      <div class="row">

        <div class="col-md-12 px-0">
          <top-navbar :title="restaurantName"></top-navbar>
          <div class="row">
            <div class="col-md-2 col-lg-2 content_sidebar">
              <ul>
                <li @click="() => toggleDiv('isImage', 1)" :class="{ activeElement : active_el == 1 }" class="list-group-item">
                  Image
                </li>
                <li @click="() => toggleDiv('isColor', 2)" :class="{ activeElement : active_el == 2 }" class="list-group-item">
                  Color
                </li>
                <li @click="() => toggleDiv('isPayment', 3)" :class="{ activeElement : active_el == 3 }" class="list-group-item">
                  Payment
                </li>
                <li @click="() => toggleDiv('isContent', 4)" :class="{ activeElement : active_el == 4 }" class="list-group-item">
                  Content
                </li>
                <li @click="() => toggleDiv('isMenu', 5)" :class="{ activeElement : active_el == 5 }" class="list-group-item">
                  Menu PDF
                </li>
                <li @click="() => toggleDiv('isSocial', 6)" :class="{ activeElement : active_el == 6 }" class="list-group-item">
                  Social Media
                </li>
                <li @click="() => toggleDiv('isTimeSlot', 7)" :class="{ activeElement : active_el == 7 }" class="list-group-item">
                  Time
                </li>
              </ul>
            </div>
            <div class="col-md-10 col-lg-10 pl-1">
              <div style="background-color: #FFFFFF; height: 90vh; overflow-y: scroll; overflow-x: hidden; margin-bottom: 0rem;">

                <all-images v-if="sidebarContentVisibility.isImage"
                  :icon="webData.favIcon"
                  :favIconPublicId="webData.favIconPublicId"
                  :logo="webData.logo"
                  :logoPublicId="webData.logoPublicId"
                  :bgImage="webData.backgroundImage"
                  :backgroundImagePublicId="webData.backgroundImagePublicId"
                  :headOneImgs="headerOneImages"
                  :headTwoImgs="headerTwoImages"
                  :headThreeImgs="headerThreeImages"
                  :requestHeader="requestHeader"
                ></all-images>

                <colors v-if="sidebarContentVisibility.isColor"
                  :webConfig="webData"
                  :requestHeader="requestHeader"
                ></colors>

                <contents v-if="sidebarContentVisibility.isContent"
                  :webConfig="webData"
                  :requestHeader="requestHeader"
                ></contents>

                <payment v-if="sidebarContentVisibility.isPayment"
                  :webConfig="webData"
                  :requestHeader="requestHeader"
                ></payment>

                <menu-PDF v-if="sidebarContentVisibility.isMenu"
                  :menu="webData.menuPdf"
                  :menuPdfPublicId="webData.menuPdfPublicId"
                  :requestHeader="requestHeader"
                ></menu-PDF>

                <social-media v-if="sidebarContentVisibility.isSocial"
                  :webConfig="webData"
                  :requestHeader="requestHeader"
                ></social-media>

                <time-slot v-if="sidebarContentVisibility.isTimeSlot"
                  :requestHeader="requestHeader"
                ></time-slot>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import TopNavbar from '../../layout/TopNavbar'
import AllImages from './v1/AllImages'
import Colors from './v1/Colors'
import Payment from './v1/Payment'
import Contents from './v1/Contents'
import MenuPDF from './v1/MenuPDF'
import SocialMedia from './v1/SocialMedia'
import TimeSlot from './v1/TimeSlot'
import axios from 'axios'
import logout from './../../mixins/logoutMixins.js'

export default {
  components: {
    TopNavbar,
    AllImages,
    Colors,
    Payment,
    Contents,
    MenuPDF,
    SocialMedia,
    TimeSlot,
  },
  data () {
    return {
      sidebarContentVisibility: {
        isImage: false,
        isColor: false,
        isPayment: false,
        isContent: false,
        isMenu: false,
        isSocial: false,
        isTimeSlot: false,
      },
      active_el: 1,
      headerOneImages: [],
      headerTwoImages: [],
      headerThreeImages: [],
      requestHeader: null,
      jwtToken: '',
      webData: '',
      restaurantName: localStorage.getItem('restaurantName'),
    }
  },
  created() {
    this.jwtToken = this.$cookies.get('token') || null
    this.webConfig()
  },
  methods: {
    webConfig(){
      // request header
      this.requestHeader = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.jwtToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "*",
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig?restaurantId='+localStorage.getItem('restaurantId')
      // send request
      axios
        .get(url,
          {
            headers: {
              Authorization: `Bearer ${this.jwtToken}`,
            }
          })
        .then((result) => {
          if (result.status === 403) {
              let logoutResponse = this.logout()
              if (logoutResponse === 1) {
                  this.$router.push({ path: '/login' })
              }
          }
          this.webData = result.data
          // initially show images
          this.sidebarContentVisibility.isImage = true

          const data = result.data
          if (typeof data.headerOneImages != 'undefined'){
            for (var i=0; i<data.headerOneImages.length; i++){
              this.headerOneImages.push({'src': data.headerOneImages[i].url, 'publicId': data.headerOneImages[i].publicId})
            }
          }
          if (typeof data.headerTwoImages != 'undefined') {
            for (var j=0; j<data.headerTwoImages.length; j++){
              this.headerTwoImages.push({'src': data.headerTwoImages[j].url, 'publicId': data.headerTwoImages[j].publicId})
            }
          }
          if (typeof data.headerThreeImages != 'undefined') {
            for (var k=0; k<data.headerThreeImages.length; k++){
              this.headerThreeImages.push({'src': data.headerThreeImages[k].url, 'publicId': data.headerThreeImages[k].publicId})
            }
          }
        })
    },
    toggleDiv(param, el_no) {
      const obj = Object.entries(this.sidebarContentVisibility)
      for (const [key, value] of obj) {
        if (key == param) {
          this.sidebarContentVisibility[key] = true
        } else {
          this.sidebarContentVisibility[key] = false
        }
      }
      this.active_el = el_no
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

.content_sidebar{background-color: #ffbfa4 !important; padding-top: 3rem; height: 91vh;}
.content_sidebar ul{padding-left: 1rem;}
.content_sidebar ul li{
  font-size: 1.2rem;
  background-color: transparent;
  border: 0px solid transparent;
  padding: 0 1.25rem;
  margin: .25rem 0;
  cursor: pointer;
}
.content_sidebar ul li.activeElement{font-weight: bold;}
</style>
