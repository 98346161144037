<template>
  <div>
    <div class="row font-weight-bold" style="background-color: antiquewhite;">
      <div class="col-md-4 col-lg-4">
        <div class="button-container">
          <div class="button" style="padding: 8px 25px;">All</div>
          <div class="button" style="padding: 8px 15px;">
            <input v-model="isUpdateAll" type="checkbox">
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4 text-center">
        <h3 class="mt-3 font-weight-bold">{{selectedDay.name}}</h3>
      </div>
      <div class="col-md-4 col-lg-4 text-right">
        <img v-if="canDeleteSelectedDay" src="/img/delete.png" width="15" alt="Delete" @click="deleteDate" class="mt-4 mr-3" style="cursor: pointer">
      </div>
    </div>

    <div class="row mt-1">
      <!-- Day List -->
      <div class="col-md-2 position-relative" style="height: 85vh;">
        <div class="day-list">
          <div
            v-for="(day, index) in days"
            :key="index"
            :class="['day-item', { active: selectedDay.id == day.id }]"
            @click="selectDay(day)"
            >
            {{ day.name }}
          </div>
        </div>
        <div class="addDateDiv position-absolute">
          <input type="date" @change="dateSelected" id="datePicker">
          <img @click="openCalendar" src="/img/calendar.png" class="mb-2" alt="Calendar">
          <p v-if="selectedDate" class="mb-0">{{ selectedDate }}</p>
          <button @click="createDateForTimeSlot" class="btn btnSubmit mt-0 px-5">Add</button>
        </div>
      </div>

      <!-- Schedule Selector -->
      <div class="col-md-3 pl-0">
        <div class="section-card" style="background-color: #FFEBD4;">
          <div class="form-group d-flex mb-0" style="align-items: center; justify-content: space-between;">
            <label for="day-status" class="mb-0 font-weight-bold">Day</label>
            <select v-model="isDayOpen" id="day-status" class="form-control w-75 font-weight-bold text-center">
              <option value="Closed">Closed</option>
              <option value="Open">Open</option>
            </select>
          </div>
        </div>
        <div class="selection-card mt-2 mealTypes">
          <button v-for="(meal, i) in selectedDay.mealOfDays" :key="i"
            :class="['btn d-block mx-auto', { active: selectedMeal.mealType === meal.mealType }]"
            @click="selectMeal(meal)">{{meal.mealType}}
          </button>
        </div>
      </div>

      <!-- Time Selectors -->
      <div class="col-md-6">
        <div class="">
          <div class="form-group mt-3 d-flex" style="align-items: center; justify-content: end;">
            <label for="lunch-status" class="mb-0 font-weight-bold mr-3">{{selectedMeal.mealType}}</label>
            <select v-model="isMealOpen" id="lunch-status" class="form-control w-50 font-weight-bold text-center">
              <option value="Closed">Closed</option>
              <option value="Open">Open</option>
            </select>
          </div>
          <div class="form-group mb-2 mt-5 d-flex" style="align-items: center; justify-content: end;">
            <label for="open-time" class="mb-0 font-weight-bold mr-3">Opens</label>
            <input type="time" v-model="openingTime" class="form-control w-50 font-weight-bold">
          </div>
          <div class="form-group d-flex" style="align-items: center; justify-content: end;">
            <label for="close-time" class="mb-0 font-weight-bold mr-3">Closes</label>
            <input type="time" v-model="closingTime" class="form-control w-50 font-weight-bold">
          </div>
          <div class="form-group mb-2 mt-5 d-flex" style="align-items: center; justify-content: end;">
            <label for="last-collection" class="mb-0 font-weight-bold mr-3">Last collection at</label>
            <input type="time" v-model="lastCollectionAt" class="form-control w-50 font-weight-bold">
          </div>
          <div class="form-group mb-2 d-flex" style="align-items: center; justify-content: end;">
            <label for="last-delivery" class="mb-0 font-weight-bold mr-3">Last delivery at</label>
            <input type="time" v-model="lastDeliveryAt" class="form-control w-50 font-weight-bold">
          </div>
          <div class="form-group d-flex" style="align-items: center; justify-content: end;">
            <label for="time-frequency" class="mb-0 font-weight-bold mr-3">Time frequency</label>
            <select v-model="intervalTime" id="time-frequency" class="form-control w-50 font-weight-bold text-center">
              <option v-for="value in getFrequency()" :key="value" :value="value">
                {{ value }}
              </option>
            </select>
          </div>
          <div class="form-group d-flex" style="align-items: center; justify-content: end;">
            <button @click="updateTimeSlot" class="btn btnSubmit btn-block w-50">Submit</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment'

export default {
  data () {
    return {
      days: [],
      selectedDay: '',
      isDayOpen: '',
      selectedMeal: [],
      isMealOpen: '',
      openingTime: '',
      closingTime: '',
      lastCollectionAt: '',
      lastDeliveryAt: '',
      intervalTime: '',
      isUpdateAll: false,
      selectedDate: '',
      jwtToken: this.$cookies.get('token') || null,
      canDeleteSelectedDay: false
    }
  },
  mounted() {
    this.getTimeSlots()
  },
  methods: {
    getTimeSlots(setFirstDay = true) {
      // request url
      let url = process.env.VUE_APP_API_URL+'/api/timeSlot?restaurantId='+localStorage.getItem('restaurantId')

      // send request
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          }
        })
        .then((data) => {
          if (data.status == 200) {
            this.days = data.data
            if(setFirstDay){
              this.selectDay(this.days[0])
            } else {
              this.days.forEach(day => {
                if(day.id === this.selectedDay.id) {
                  this.selectDay(day, setFirstDay)
                }
              })
            }
          }
        })
    },
    selectDay(day, setFirstMeal = true) {
      this.selectedDay = day
      this.isDayOpen = day.hasWeekDaysOpen

      if(setFirstMeal){
        this.selectMeal(day.mealOfDays[0])
      } else {
        this.selectedDay.mealOfDays.forEach(meal => {
          if(meal.mealType === this.selectedMeal.mealType) {
            this.selectMeal(meal)
          }
        })
      }
      this.canDeleteSelectedDay = day.weekdays !== 'Yes' ? true : false
    },
    selectMeal(meal) {
      this.selectedMeal = meal;
      this.isMealOpen = meal.hasOpen
      this.openingTime = meal.openingTime ? moment(meal.openingTime, 'hh:mm A').format('HH:mm') : ''
      this.closingTime = meal.closingTime ? moment(meal.closingTime, 'hh:mm A').format('HH:mm') : ''
      this.lastCollectionAt = meal.collectionEnd ? moment(meal.collectionEnd, 'hh:mm A').format('HH:mm') : ''
      this.lastDeliveryAt = meal.deliveryEnd ?  moment(meal.deliveryEnd, 'hh:mm A').format('HH:mm') : ''
      this.intervalTime = meal.intervalTime
    },
    getFrequency() {
      const options = [];
      for (let i = 5; i <= 60; i += 5) {
        options.push(i);
      }
      return options;
    },
    updateTimeSlot() {
      let updatePromises = [];

      if (this.isUpdateAll) {
        // Collect all API promises
        updatePromises = this.days.map(day => this.singleDayTimeSlotUpdate(day.id));
      } else {
        updatePromises = [this.singleDayTimeSlotUpdate(this.selectedDay.id)];
      }

      // Wait for all API calls to finish before calling getTimeSlots()
      Promise.all(updatePromises)
        .then(() => {
          this.getTimeSlots(false);
          this.$toastr.s("Updated successfully", "Success");
        })
        .catch(error => {
          console.error("Error updating time slots:", error);
        });
    },
    singleDayTimeSlotUpdate(id) {
      let url = process.env.VUE_APP_API_URL+'/api/timeSlot/updateTimeSlot'

      const data = {
        "id": id,
        "hasWeekDaysOpen": this.isDayOpen,
        "mealOfDays": {
          "mealType": this.selectedMeal.mealType,
          "hasOpen": this.isMealOpen,
          "openingTime": moment(this.openingTime, 'hh:mm A').format('hh:mm A'),
          "closingTime": moment(this.closingTime, 'hh:mm A').format('hh:mm A'),
          "intervalTime": this.intervalTime,
          "collectionEnd": moment(this.lastCollectionAt, 'hh:mm A').format('hh:mm A'),
          "deliveryEnd": moment(this.lastDeliveryAt, 'hh:mm A').format('hh:mm A')
        }
      }

      return axios
        .post(url, data, {
          headers: { Authorization: `Bearer ${this.jwtToken}` }
        })
        .then((result) => {})
    },
    openCalendar() {
      let input = document.querySelector("#datePicker");
      input.showPicker();
    },
    dateSelected() {
      let date = document.getElementById("datePicker").value;
      this.selectedDate = moment(date).format("DD/MM/YYYY");
    },
    createDateForTimeSlot() {
      if (this.selectedDate == '') {
        this.$toastr.w("Please select date", "Warning");
        return
      }
      let url = process.env.VUE_APP_API_URL+'/api/timeSlot/create'

      const data = {
        "restaurantId": localStorage.getItem('restaurantId'),
        "name": this.selectedDate
      }

      axios
        .post(url, data, {
          headers: { Authorization: `Bearer ${this.jwtToken}` }
        })
        .then((result) => {
          this.getTimeSlots()
          this.$toastr.s("Date created successfully", "Success");
          this.selectedDate = ''
        })
    },
    deleteDate() {
      if(this.selectedDay.weekdays === 'Yes') return

      if (confirm('Are you sure to delete?')){
        let url = process.env.VUE_APP_API_URL+'/api/timeSlot/delete?id='+this.selectedDay.id

        axios
          .get(url, {
            headers: { Authorization: `Bearer ${this.jwtToken}` }
          })
          .then((result) => {
            this.getTimeSlots()
            this.$toastr.s("Date deleted successfully", "Success");
            this.selectedDate = ''
          })
      }
    }
  },
}
</script>
<style scoped>
select{border-radius: 7px;}
.button-container {
  display: flex;
  gap: 0px;
  background-color: #ffe9d9;
  padding: 10px 5px;
  border-radius: 10px;
  width: fit-content;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.button input{width: 15px; height: 15px;}

.day-list {
  overflow-y: scroll;
  padding-bottom: 2rem;
  height: 60vh;
  scrollbar-width: thin;
}
.day-item {
  padding: 10px 15px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  text-align: center;
  margin: .5rem 0;
  cursor: pointer;
  font-weight: bold;
}
.day-item:first-child{margin-top: 0;}
.day-item.active {
  background-color: #ffe0c1;
  font-weight: bold;
}
.section-card {
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.btn-custom {
  border-radius: 20px;
  color: white;
  background: linear-gradient(to right, #ff4500, #ff5733);
  border: none;
}
.btn-custom:hover {
  background: linear-gradient(to right, #ff5733, #ff4500);
}
.mealTypes{
  background-color: #FFEBD4; padding: 5rem 0; text-align: center
}
.mealTypes button{
  border: unset;
  margin: 2rem 0;
  background: #fff;
  font-weight: bold;
  width: 45%;
  color: #000;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.btnSubmit{
  border: unset;
  margin-top: 1rem;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 93.77%);
}
.mealTypes button:first-child{margin-top: 0;}
.mealTypes button.active{
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 93.77%);
  color: #fff;
}

#day-status{background-color: transparent;}
#day-status, #lunch-status, #open-time, #close-time, #last-collection, #last-delivery, #time-frequency{
  border: 1px solid #414141;
}
.addDateDiv{width: 90%; text-align: center; bottom: 2rem;}
.addDateDiv img{display: block; margin: 0 auto;}
#datePicker{opacity: 0;}
</style>
