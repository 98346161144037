<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-12 px-0">
          <top-navbar :title="restaurantName" search="false"></top-navbar>
        </div>
        <div class="col-md-10 offset-md-1 col-12 text-center" style="margin-top: 2rem">
          <div class="row">
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/create-owner?edit=true" class="restaurantBox">
                <img src="/img/business-details.png" alt="Logo">
                <h4 class="text-white">Business & Owner Details</h4>
              </sidebar-link>
            </div>
            <div class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/menu-management" class="restaurantBox">
                <img src="/img/menu-entry.png" alt="Logo">
                <h4 class="text-white">Menu Entry</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/content-management" class="restaurantBox">
                <img src="/img/website-update.png" alt="Logo">
                <h4 class="text-white">Website Update</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/content-management-v2" class="restaurantBox">
                <img src="/img/website-update.png" alt="Logo">
                <h4 class="text-white">Website Update V2</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/booking-list" class="restaurantBox">
                <img src="/img/website-update.png" alt="Logo">
                <h4 class="text-white">Booking List</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/customer-list" class="restaurantBox">
                <i style="color: #fff; font-size: 2.7rem;" class="nc-icon nc-single-02"></i>
                <h4 class="text-white">Customer List</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <sidebar-link to="/admin/best-sells" class="restaurantBox">
                <i style="color: #fff; font-size: 2.7rem;" class="nc-icon nc-album-2"></i>
                <h4 class="text-white">Best Sells</h4>
              </sidebar-link>
            </div>
            <div v-if="userRole == 'Admin'" class="col-md-4 col-12 px-0">
              <div @click="toggleStatus" class="toggle-container mx-auto" id="toggleBtn">
                <span class="toggle-text">{{ rposStatus == 'Yes' ? 'ON' : 'OFF' }}</span>
                <div class="toggle-switch"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <sidebar-link to="/admin/owner-list" class="backBtn">
              <img src="/img/arrow-left.png" alt="Logo">
            </sidebar-link>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
  // import { mapState } from 'vuex'
  import TopNavbar from '../../layout/TopNavbar'
  import axios from 'axios'

  export default {
    components: {
      TopNavbar
    },
    data () {
      return {
        ownerMgmt: false,
        isMenuManagement: false,
        restaurantName: localStorage.getItem('restaurantName'),
        userRole: this.$cookies.get('adminData').role,
        webData: '',
        rposStatus: '',
      }
    },
    mounted() {
      this.getRestaurantData()
    },
    methods: {
      getRestaurantData(){
        let url = process.env.VUE_APP_API_URL+'/api/restaurant/find?id='+localStorage.getItem('restaurantId')
        axios
          .get(url, {headers: {Authorization: `Bearer ${this.$cookies.get('token')}`}})
          .then((result) => {
            this.webData = result.data
            this.rposStatus = result.data.rposEnable
            this.setToggleBtn()
          })
      },
      ownerManagement () {
        this.ownerMgmt = !this.ownerMgmt
      },
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      toggleStatus(){
        this.rposStatus = this.rposStatus == 'Yes' ? 'No' : 'Yes'
        const url = process.env.VUE_APP_API_URL+'/api/restaurant/update'
        this.webData.rposEnable = this.rposStatus
        this.setToggleBtn()

        axios
        .post(url, this.webData, {headers: {Authorization: `Bearer ${this.$cookies.get('token')}`}})
        .then((data) => {
          if (data.status == 200) {
            this.$toastr.s(`rPos ${this.rposStatus == 'Yes' ? 'Enabled' : 'Disabled'}.`, "Success");
          }
        })
      },
      setToggleBtn(){
        const toggleBtn = document.getElementById("toggleBtn");
        const text = document.querySelector(".toggle-text");
        const toggleSwitch = document.querySelector(".toggle-switch");
        if(this.rposStatus == "Yes"){
          toggleBtn.classList.remove("inactive");
          text.style.transform = "translateX(0)";
          toggleSwitch.innerHTML = '<img src="/img/rpos-on.png" width="65%" height="100" alt="icon">';
        }else{
          toggleBtn.classList.add("inactive");
          text.style.transform = "translateX(150px)";
          toggleSwitch.innerHTML = '<img src="/img/rpos-off.png" width="65%" height="100" alt="icon">';
        }
      }
    },
    watch: {
      $route (to, form) {
        if (to.name == 'MenuManagement' && to.path == '/admin/menu-management') {
          this.isMenuManagement = true
        }
        else {
          this.isMenuManagement = false
        }
      }
    }
  }

</script>
<style scoped>
  .restaurantBox{
    width: 315px;
    height: 150px;
    background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 93.77%);
    border-radius: 10px;
    text-align: center;
    margin: 1rem;
    display: inline-grid;
  }
  .backBtn{
    width: 200px;
    height: 50px;
    background: linear-gradient(179.48deg, #7A7A7A 36.9%, #1C1C1C 93.77%);
    border-radius: 10px;
    text-align: center;
    margin: 3rem auto 0 auto;
    display: block;
  }
  .backBtn img{width: 40px; height: 30px;}
  .toggle-container {
    position: relative;
    width: 315px;
    height: 150px;
    margin-top: 1rem;
    border-radius: 10px;
    box-shadow: -2px 3px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    padding: 0 !important;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    user-select: none;
    transition: background 0.3s ease-in-out;
    background-color: #078400;
  }
  .toggle-container.inactive{background: #F40000;}
  .toggle-text {
    width: 50%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    font-size: 40px;
    color: #fff;
    font-weight: bold;
    border-radius: 10px 0 0 10px;
  }
  .inactive .toggle-text{
    border-radius: 0 10px 10px 0;
  }
  .toggle-switch {
    position: absolute;
    right: 0px;
    width: 50%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  .inactive .toggle-switch {
    transform: translateX(-157px);
  }
</style>
