<template>
  <div>
    <h2 class="text-center mt-0 pt-4" style="border-bottom: 2px solid;">Content</h2>
    <h3 class="content-heading pl-4">Hours</h3>
    <div class="row pl-5">
      <div class="col-md-2 col-11">
        <input type="text" class="form-control" v-model="openingTime" placeholder="00:00">
      </div>-
      <div class="col-md-2 col-11 contentPaddingClass">
        <input type="text" class="form-control" v-model="closingTime" placeholder="00:00">
      </div>
    </div>
    <!-- <h3 class="content-heading">Time Slot Applicable</h3>
    <div class="row pl-5">
      <div class="col-md-2 col-11">
        <select v-model="timeSlotApplicable" class="form-control mb-3">
          <option value="" selected disabled>Choose value</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
    </div> -->
    <h3 class="content-heading pl-4">Latitude/Longitude</h3>
    <div class="row pl-5">
      <div class="col-md-3 col-11">
        <input type="text" class="form-control" v-model="latitude" placeholder="latitude">
      </div>
      <div class="col-md-3 col-11 contentPaddingClass">
        <input type="text" class="form-control" v-model="longitude" placeholder="longitude">
      </div>
    </div>
    <h3 class="content-heading pl-4">Web Address</h3>
    <div class="row pl-5">
      <div class="col-md-6 col-11">
        <input type="text" class="form-control" v-model="webAddress" placeholder="Web address">
      </div>
    </div>
    <h3 class="content-heading pl-4">Map</h3>
    <div class="row pl-5">
      <div class="col-md-11 col-11">
        <textarea class="form-control" v-model="mapIframe" placeholder="Map" cols="10" rows="5"></textarea>
      </div>
    </div>

    <div class="row content-heading">
      <div class="col-md-3 col-sm-12">
        <h3 class="mt-5 pl-4">Mission Statement</h3>
      </div>
      <div class="col-md-3 col-sm-12 mt-2">
        <label for="">Font Color</label>
        <input type="text" class="form-control" v-model="aboutTextColor" placeholder="#FFFFFF">
      </div>
      <div class="col-md-3 col-sm-12 my-2">
        <label for="">Background Color</label>
        <input type="text" class="form-control" v-model="aboutBackgroundColor" placeholder="Mission text color">
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12 px-5">
        <vue-editor id="aboutUs" v-model="aboutUs" />
      </div>
    </div>
    <div class="row content-heading">
      <div class="col-md-4 col-sm-12">
        <h3 class="mt-5 pl-4">Welcome Message</h3>
      </div>
      <div class="col-md-3 col-sm-12 mt-2">
        <label for="">Font Color</label>
        <input type="text" class="form-control" v-model="welcomeTextColor" placeholder="#FFFFFF">
      </div>
      <div class="col-md-3 col-sm-12 my-2">
        <label for="">Background Color</label>
        <input type="text" class="form-control" v-model="welcomeBackgroundColor" placeholder="#FFFFFF">
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 px-5">
        <vue-editor v-model="welcomeTxt" />
      </div>
    </div>
    <h3 class="content-heading mt-5 pl-4">Privacy Policy</h3>
    <div class="row">
      <div class="col-md-12 px-5">
        <vue-editor v-model="privacyPolicy" />
      </div>
    </div>
    <h3 class="content-heading mt-5 pl-4">Terms & Condition</h3>
    <div class="row">
      <div class="col-md-12 px-5">
        <vue-editor v-model="termsCondition" />
      </div>
    </div>
    <h3 class="content-heading mt-5 pl-4">Allergy Information</h3>
    <div class="row">
      <div class="col-md-12 px-5">
        <vue-editor v-model="allergyInformation" />
      </div>
    </div>
    <h3 class="content-heading mt-5 pl-4">Lunch Opening Hours</h3>
    <div class="row">
      <div class="col-md-12 px-5">
        <vue-editor v-model="lunchOpeningHours" />
      </div>
    </div>
    <div class="col-md-12 p-5 contentBtnAlign">
      <button class="btn submitBtn" @click="updateWebConfig">Upload</button>
      <img v-if="showWebConfigLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: [
    'webConfig',
    'requestHeader',
  ],
  data() {
    return {
      openingTime: '',
      closingTime: '',
      timeSlotApplicable: '',
      latitude: '',
      longitude: '',
      webAddress: '',
      mapIframe: '',
      aboutUs: '',
      aboutTextColor: '',
      aboutBackgroundColor: '',
      welcomeTxt: '',
      welcomeTextColor: '',
      welcomeBackgroundColor: '',
      privacyPolicy: '',
      termsCondition: '',
      allergyInformation: '',
      lunchOpeningHours: '',
      showWebConfigLoading: false
    }
  },
  mounted() {
    this.openingTime = this.webConfig.openingTime
    this.closingTime = this.webConfig.closingTime
    this.timeSlotApplicable = this.webConfig.timeSlotApplicable
    this.latitude = this.webConfig.lat
    this.longitude = this.webConfig.long
    this.webAddress = this.webConfig.webAddress
    this.mapIframe = this.webConfig.mapIframe
    this.aboutUs = this.webConfig.aboutUs
    this.aboutTextColor = this.webConfig.aboutTextColor
    this.aboutBackgroundColor = this.webConfig.aboutBackgroundColor
    this.welcomeTxt = this.webConfig.welcomeTxt
    this.welcomeTextColor = this.webConfig.welcomeTextColor
    this.welcomeBackgroundColor = this.webConfig.welcomeBackgroundColor
    this.privacyPolicy = this.webConfig.privacyPolicy
    this.termsCondition = this.webConfig.termsCondition
    this.allergyInformation = this.webConfig.allergyInformation
    this.lunchOpeningHours = this.webConfig.lunchOpeningHours
  },
  methods: {
    updateWebConfig(){
      this.showWebConfigLoading = true

      // request data
      let dataToSubmit = {
        "restaurantId": localStorage.getItem('restaurantId'),
        "welcomeTxt": this.welcomeTxt,
        "aboutUs": this.aboutUs,
        "privacyPolicy": this.privacyPolicy,
        "termsCondition": this.termsCondition,
        "lat": this.latitude,
        "long": this.longitude,
        "mapIframe": this.mapIframe,
        "stripeSecretKey": this.webConfig.stripeSecretKey,
        "stripePublishKey": this.webConfig.stripePublishKey,
        "elavonUserName": this.webConfig.elavonUserName,
        "elavonPassword": this.webConfig.elavonPassword,
        "defaultPaymentGateway": this.webConfig.defaultPaymentGateway,
        "headerColor": this.webConfig.headerColor,
        "navigationColor": this.webConfig.navigationColor,
        "navbarHoverColor": this.webConfig.navbarHoverColor,
        "menuIconBgColor": this.webConfig.menuIconBgColor,
        "mobileNavBgColor": this.webConfig.mobileNavBgColor,
        "alertBgColor": this.webConfig.alertBgColor,
        "alertTextColor": this.webConfig.alertTextColor,
        "alertBoxBtnBgColor": this.webConfig.alertBoxBtnBgColor,
        "alertBoxBtnFontColor": this.webConfig.alertBoxBtnFontColor,
        "welcomeTextColor": this.welcomeTextColor,
        "welcomeBackgroundColor": this.welcomeBackgroundColor,
        "welcomeBgShadowColor": this.webConfig.welcomeBgShadowColor,
        "sliderText": this.webConfig.sliderText,
        "headerTwoBackgroundColor": this.webConfig.headerTwoBackgroundColor,
        "aboutTextColor": this.aboutTextColor,
        "aboutBackgroundColor": this.aboutBackgroundColor,
        "reviewTextColor": this.webConfig.reviewTextColor,
        "reviewFontColor": this.webConfig.reviewFontColor,
        "reviewBackgroundColor": this.webConfig.reviewBackgroundColor,
        "footerColor": this.webConfig.footerColor,
        "footerTextColor": this.webConfig.footerTextColor,
        "rightsReservedTextColor": this.webConfig.rightsReservedTextColor,
        "poweredByColor": this.webConfig.footerPoweredByColor,
        "categoryBgForDesktop": this.webConfig.categoryBgForDesktop,
        "categoryBgForMobile": this.webConfig.categoryBgForMobile,
        "categoryHoverBgColor": this.webConfig.categoryHoverBgColor,
        "categoryHoverTextColor": this.webConfig.categoryHoverTextColor,
        "cartItemBgColor": this.webConfig.cartItemBgColor,
        "buttonColor": this.webConfig.buttonColor,
        "buttonFontColor": this.webConfig.buttonFontColor,
        "contactPageBgColor": this.webConfig.contactPageBgColor,
        "openingTime": this.openingTime,
        "closingTime": this.closingTime,
        // "timeSlotApplicable": this.timeSlotApplicable,
        "allergyInformation": this.allergyInformation,
        "lunchOpeningHours": this.lunchOpeningHours,
        "socialMedia": this.webConfig.socialMedia,
        "webAddress": this.webAddress
      }

      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/update'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showWebConfigLoading = false
            this.$toastr.s("Successfully Updated.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
  }
}
</script>
