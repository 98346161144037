<template>
  <div>
    <h2 class="text-center mt-0 pt-4" style="border-bottom: 2px solid;">Social Media</h2>
    <div class="row">
      <div class="col-md-4 col-11 pl-5 form-group">
        <select v-model="socialMediaType" class="form-control mb-3">
          <option value="0" disabled>Select Media</option>
          <option value="fb">Facebook</option>
          <option value="google">Google</option>
          <option value="insta">Instagram</option>
          <option value="twitter">Twitter</option>
          <option value="linkedin">Linkedin</option>
        </select>
        <label for="socialMedia">Link</label>
        <input type="text" v-model="socialMediaUrl" class="form-control" id="socialMedia" placeholder="Social media URL">
      </div>
      <div class="col-md-7 col-11 pl-5 form-group">
        <div v-for="(item, index) in socialMedia" :key="index">
          <h4 class="my-0">{{ item.type }}
            <span @click="socialMedia.splice(index, 1)" class="removeSocialBtn">x</span>
          </h4>
          <p style="word-break: break-all">{{ item.link }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-12 p-5 contentBtnAlign">
      <button class="btn submitBtn" @click="updateWebConfig">Upload</button>
      <img v-if="showWebConfigLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['webConfig', 'requestHeader'],
  data() {
    return {
      socialMedia: [],
      socialMediaType: '0',
      socialMediaUrl: '',
      showWebConfigLoading: false
    }
  },
  mounted() {
    if (typeof this.webConfig.socialMedia !== 'undefined') {
      this.socialMedia = this.webConfig.socialMedia
    }
  },
  methods: {
    updateWebConfig(){
      //social media payload
      if (this.socialMediaType !== '0') {
        this.showWebConfigLoading = true
        let isUpdate = false
        this.socialMedia.forEach(item => {
          if (item.type === this.socialMediaType) {
            item.link = this.socialMediaUrl
            isUpdate = true
          }
        })
        if (!isUpdate) {
          const data =
            {
              "type": this.socialMediaType,
              "link": this.socialMediaUrl
            }
          this.socialMedia.push(data)
        }
      }
      // request data
      let dataToSubmit = {
        "restaurantId": localStorage.getItem('restaurantId'),
        "welcomeTxt": this.webConfig.welcomeTxt,
        "aboutUs": this.webConfig.aboutUs,
        "privacyPolicy": this.webConfig.privacyPolicy,
        "termsCondition": this.webConfig.termsCondition,
        "lat": this.webConfig.lat,
        "long": this.webConfig.long,
        "mapIframe": this.webConfig.mapIframe,
        "stripeSecretKey": this.webConfig.stripeSecretKey,
        "stripePublishKey": this.webConfig.stripePublishKey,
        "elavonUserName": this.webConfig.elavonUserName,
        "elavonPassword": this.webConfig.elavonPassword,
        "defaultPaymentGateway": this.webConfig.defaultPaymentGateway,
        "headerColor": this.webConfig.headerColor,
        "navigationColor": this.webConfig.navigationColor,
        "navbarHoverColor": this.webConfig.navbarHoverColor,
        "menuIconBgColor": this.webConfig.menuIconBgColor,
        "mobileNavBgColor": this.webConfig.mobileNavBgColor,
        "alertBgColor": this.webConfig.alertBgColor,
        "alertTextColor": this.webConfig.alertTextColor,
        "alertBoxBtnBgColor": this.webConfig.alertBoxBtnBgColor,
        "alertBoxBtnFontColor": this.webConfig.alertBoxBtnFontColor,
        "welcomeTextColor": this.webConfig.welcomeTextColor,
        "welcomeBackgroundColor": this.webConfig.welcomeBackgroundColor,
        "welcomeBgShadowColor": this.webConfig.welcomeBgShadowColor,
        "sliderText": this.webConfig.sliderText,
        "headerTwoBackgroundColor": this.webConfig.headerTwoBackgroundColor,
        "aboutTextColor": this.webConfig.aboutTextColor,
        "aboutBackgroundColor": this.webConfig.aboutBackgroundColor,
        "reviewTextColor": this.webConfig.reviewTextColor,
        "reviewFontColor": this.webConfig.reviewFontColor,
        "reviewBackgroundColor": this.webConfig.reviewBackgroundColor,
        "footerColor": this.webConfig.footerColor,
        "footerTextColor": this.webConfig.footerTextColor,
        "rightsReservedTextColor": this.webConfig.rightsReservedTextColor,
        "poweredByColor": this.webConfig.footerPoweredByColor,
        "categoryBgForDesktop": this.webConfig.categoryBgForDesktop,
        "categoryBgForMobile": this.webConfig.categoryBgForMobile,
        "categoryHoverBgColor": this.webConfig.categoryHoverBgColor,
        "categoryHoverTextColor": this.webConfig.categoryHoverTextColor,
        "cartItemBgColor": this.webConfig.cartItemBgColor,
        "buttonColor": this.webConfig.buttonColor,
        "buttonFontColor": this.webConfig.buttonFontColor,
        "contactPageBgColor": this.webConfig.contactPageBgColor,
        "openingTime": this.webConfig.openingTime,
        "closingTime": this.webConfig.closingTime,
        // "timeSlotApplicable": this.timeSlotApplicable,
        "allergyInformation": this.webConfig.allergyInformation,
        "lunchOpeningHours": this.webConfig.lunchOpeningHours,
        "socialMedia": this.socialMedia,
        "webAddress": this.webConfig.webAddress
      }

      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/update'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showWebConfigLoading = false
            this.$toastr.s("Successfully Updated.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
  }
}
</script>
<style scoped>
.removeSocialBtn{
  color: red;
  cursor: pointer;
}
</style>
