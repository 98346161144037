<template>
  <div class="content pt-0">
    <div class="container">
      <div class="row" style="margin-bottom: 10% !important;">
        <div class="col-md-4">
          <div class="card-header" style="background: #FDEAD5; color: #000; padding: 10px;">
            <span style="font-size: 1.3rem; font-weight: bold">Topping Category</span>
          </div>
          <ul class="list-group list-group-flush" style="height: 50%;">
            <li
              v-for="(topping, index) in toppingsDataList"
              :key="index"
              @click="() => chooseToppingCategory(topping.id, index)"
              class="list-group-item topping-list"
              style="cursor: pointer;"
              :style="{backgroundColor: assignedToppingId == topping.id ? '#FDEAD5' : '#fff'}"
              :id="'toppingChoosenId'+index">
                <span>
                <span> {{topping.title}} </span>
                <i class="fa fa-chevron-right float-right pt-1"></i>
                </span>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <div class="card-header" style="background: #FDEAD5; color: #000; padding: 10px;">
            <span style="font-size: 1.3rem; font-weight: bold">Toppings</span>
          </div>
          <div class="pl-3 pt-3" style="height: calc(100vh - 100px); border: 1px solid rgba(0, 0, 0, 0.125)">
            <input v-if="selectedTopping" type="checkbox" @click="selectAll" class="itemClass" id="selectAllBtn"> All <hr>
            <div v-for="(item, index) in selectedTopping.items" :key="index">
              <input type="checkbox" v-model="webToppingItems" :value="item.id" class="itemClass my-2">{{item.title}}
            </div>

            <div class="btnDiv">
              <div class="hookPlatform">
                <span class="checkbox-container">
                  <input type="checkbox" v-model="toppingForPos" style="width: 17px; height: 17px"> Pos
                </span>
                <span class="checkbox-container">
                  <input type="checkbox" v-model="toppingForWeb" style="width: 17px; height: 17px"> Web
                </span>
              </div>
              <div>
                <button type="button" class="cancelBtn" @click="backToMenu">Cancel</button>
                <button type="button" class="cancelBtn" @click="clearItems">Clear</button>
                <button type="button" class="submitBtn" @click="hookToppings">Hook</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['item', 'selection', 'webHookFor'],
  data () {
    return {
      restaurantId: parseInt(localStorage.getItem('restaurantId')),
      jwtToken: '',
      requestHeader: '',
      toppingsDataList: [],
      selectedTopping: {},
      webToppingItems: [],
      isAllItemsSelected: false,
      assignedToppingId: '',
      toppingForPos: false,
      toppingForWeb: false,
    }
  },
  created () {
    this.jwtToken = this.$cookies.get('token') || null
    this.requestHeader = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.jwtToken}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Headers": "*",
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
    this.webToppingsList ()
  },
  methods: {
    addTopping () {
      if (!this.toppingTitle || !this.toppingMin || !this.toppingMax || !this.toppingFree) {
        this.$toastr.w("Fill up all fields", "Warning");
        return
      }
      this.isLoading = true

      let url = process.env.VUE_APP_API_URL+'/api/webTopping/create'

      let dataToSubmit = {
        "restaurantId": this.restaurantId,
        "title": this.toppingTitle,
        "minQty": this.toppingMin,
        "maxQty": this.toppingMax,
        "freeQty": this.toppingFree,
      }
      axios
      .post(url, dataToSubmit, { headers: this.requestHeader })
      .then((data)=> {
        this.$toastr.s("Topping created successfully", "Success");

        this.toppingTitle = ''
        this.toppingMin = ''
        this.toppingMax = ''
        this.toppingFree = ''
        this.webToppingsList ()
        this.isLoading = false
      })
      .catch((e)=>{this.isLoading = false})
    },
    webToppingsList () {
      let url = process.env.VUE_APP_API_URL+`/api/webTopping?restaurantId=${this.restaurantId}`

      axios
      .get(url, { headers: this.requestHeader })
      .then((data)=> {
        this.toppingsDataList = data.data
        if (this.webHookFor == 'Item') {
          if(this.item.webToppingItems || this.item.posToppingItems) {
            this.webToppingItems = this.item.webToppingItems ? this.item.webToppingItems : this.item.posToppingItems
            this.assignedToppingId = this.item.webToppingId ? this.item.webToppingId : this.item.posToppingId
            this.toppingForPos = this.item.posToppingItems ? true : false
            this.toppingForWeb = this.item.webToppingItems ? true : false
            this.chooseToppingCategory(this.assignedToppingId, '')
          }
        } else if(this.webHookFor == 'Selection') {

        console.log('aaaaaaaaaaaaa');
        console.log(this.selection);
          if (this.selection.webToppingItems || this.selection.posToppingItems) {
            this.webToppingItems = this.selection.webToppingItems ? this.selection.webToppingItems : this.selection.posToppingItems
            this.assignedToppingId = this.selection.webToppingId ? this.selection.webToppingId : this.selection.posToppingId
            this.toppingForPos = this.selection.posToppingItems ? true : false
            this.toppingForWeb = this.selection.webToppingItems ? true : false
            this.chooseToppingCategory(this.assignedToppingId, 'update')
          }
        }
      })
    },
    backToToppingList () {
      this.toppingEditClicked = false
      this.toppingTitle = ''
      this.toppingMin = ''
      this.toppingMax = ''
      this.toppingFree = ''
    },
    chooseToppingCategory (id, index) {
      for (var i = 0; i < this.toppingsDataList.length; i++) {
        if (this.toppingsDataList[i].id == id) {
          this.selectedTopping = this.toppingsDataList[i]
          break
        }
      }
      if (index !== 'update') {
        // remove other list bg color
        let catItemList = document.getElementsByClassName('topping-list')
        for (var i = 0; i < catItemList.length; i++) {
          catItemList[i].style.backgroundColor = '#ffffff'
        }
        //set selected topping bg color
        let categoryBackground = 'toppingChoosenId'+index
        let x = document.getElementById(categoryBackground)
        x.style.background = "#FDEAD5"
        this.webToppingItems = []
      }

      this.isAllItemsSelected = false
      document.getElementById("selectAllBtn").checked = false;
    },
    selectAll () {
      if (this.isAllItemsSelected) {
        this.webToppingItems = []
        this.isAllItemsSelected = false
      } else {
        let selectedItems = []
        for (var i = 0; i < this.selectedTopping.items.length; i++) {
          selectedItems.push(this.selectedTopping.items[i].id)
        }
        this.webToppingItems = selectedItems
        this.isAllItemsSelected = true
      }
    },
    clearItems () {
      this.webToppingItems = []
      this.isAllItemsSelected = false
      document.getElementById("selectAllBtn").checked = false
    },
    hookToppings () {
      let url = process.env.VUE_APP_API_URL+`/api/menu/update`
      let dataToSubmit = ''
      if (this.webHookFor == 'Item') {
        dataToSubmit = {
          "id": this.item.id,
          "restaurantId": this.restaurantId,
          "label": this.item.label,
          "resourceRef": "",
          "refCode": 0,
          "imgURL": "",
          "iconURL": "",
          "daysOff": this.item.daysOff,
          "description": this.item.description,
          "categoryId": this.item.categoryId,
          "itemId": 0,
          "selectionId": 0,
          "toppingsId": 0,
          "modifierId": 0,
          "mealDealId": 0,
          "setId": 0,
          "printOptionId": this.item.printOptionId,
          "printOptionTitle": "",
          "terminalOption": "",
          "status": this.item.status,
          "basePrice": this.item.basePrice,
          "toppingsCategory": "",
          "mealAssignFor": this.item.mealAssignFor,
          "toppingsCategoryId": 0,
          "baseQty": 1,
          "minQty": 0,
          "maxQty": 0,
          "dataType":  this.item.dataType,
          "mealDealType": this.item.mealDealType,
          "displayOrder": 2,
          "createdAt": "3 Sep 2020 11:05:30",
          "updatedAt": "30 Sep 2020 11:05:30",
          "priceDifference": this.item.priceDifference,
          "inPrice": this.item.inPrice,
          "outPrice": this.item.outPrice,
          "children": [],
          "specialItem": this.item.specialItem,
          "shortId": this.item.shortId
        }
        if(this.toppingForPos) {
          dataToSubmit['posToppingItems'] = this.webToppingItems
          dataToSubmit['posToppingId'] = this.selectedTopping.id
        }
        if(this.toppingForWeb) {
          dataToSubmit['webToppingItems'] = this.webToppingItems
          dataToSubmit['webToppingId'] = this.selectedTopping.id
        }
      } else {
        dataToSubmit = {
          "id": this.selection.id,
          "restaurantId": this.restaurantId,
          "label": this.selection.label,
          "resourceRef": "",
          "refCode": 0,
          "imgURL": "",
          "iconURL": "",
          "daysOff": this.selection.daysOff,
          "description": this.selection.description,
          "categoryId": this.selection.categoryId,
          "itemId": this.selection.itemId,
          "selectionId": 0,
          "toppingsId": 0,
          "modifierId": 0,
          "mealDealId": 0,
          "setId": 0,
          "printOptionId": this.selection.printOptionId,
          "printOptionTitle": "",
          "terminalOption": "",
          "status": this.selection.status,
          "basePrice": this.selection.basePrice,
          "toppingsCategory": "",
          "mealAssignFor": this.selection.mealAssignFor,
          "toppingsCategoryId": 0,
          "baseQty": 1,
          "minQty": 0,
          "maxQty": 0,
          "dataType": "Selection",
          "mealDealType": "",
          "displayOrder": 2,
          "createdAt": "3 Sep 2020 11:05:30",
          "updatedAt": "30 Sep 2020 11:05:30",
          "priceDifference": this.selection.priceDifference,
          "inPrice": this.selection.inPrice,
          "outPrice": this.selection.outPrice,
          "children": [],
          "specialItem": this.selection.specialItem,
          "shortId": this.selection.shortId
        }
        if(this.toppingForPos) {
          dataToSubmit['posToppingItems'] = this.webToppingItems
          dataToSubmit['posToppingId'] = this.selectedTopping.id
        }
        if(this.toppingForWeb) {
          dataToSubmit['webToppingItems'] = this.webToppingItems
          dataToSubmit['webToppingId'] = this.selectedTopping.id
        }
      }

      axios
      .post(url, dataToSubmit,
      {
        headers: this.requestHeader
      })
      .then((data)=> {
        this.$toastr.s("Topping added successfully", "Success");
        this.backToMenu()
      })
    },
    backToMenu () {
      this.$emit('backFromWebToppings', this.webHookFor, this.item, this.selection)
    }
  }
}
</script>

<style scoped>
.btnDiv{
  position: absolute;
  bottom: 10px;
  width: 84%;
}
.btnDiv div{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.hookPlatform{
  font-weight: bold;
  padding: 10px 20px;
  margin-bottom: .5rem;
  background-color: #FDEAD5;
  display: flex;
  gap: 10px;
}

.hookPlatform .checkbox-container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.checkbox-container input[type="checkbox"] {
  margin-top: -3px;
}
.cancelBtn{
  padding: 6px 20px;
  background: #fff;
  color: #000;
  border: unset;
  border-radius: 10px;
  box-shadow: 1px 3px 6px 0px #888888;
}
.submitBtn{
  padding: 6px 20px;
  background: linear-gradient(179.48deg, #FD7902 36.9%, #FF0101 93.77%);
  color: #fff;
  border: unset;
  border-radius: 10px;
  box-shadow: 1px 3px 6px 0px #888888;
}
.overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}
.list-group-flush > .list-group-item{border-width: unset; border:1px solid rgba(0, 0, 0, 0.125);}
.itemClass{margin-right: .5rem; height: 20px; width: 20px; }
</style>
