<template>
  <div>
    <h2 class="text-center mt-0 pt-4" style="border-bottom: 2px solid;">Payment Gateways</h2>
    <div class="row pl-5">
      <div class="col-md-12 col-11">
        <label for="">Stripe secret key</label>
        <input type="text" class="form-control" v-model="stripeSecretKey" placeholder="Secret key">
      </div>
      <div class="col-md-12 col-11 mt-3">
        <label for="">Stripe publish key</label>
        <input type="text" class="form-control" v-model="stripePublishKey" placeholder="Publish key">
      </div>
    </div>
    <!-- <div class="row pl-5">
      <div class="col-md-12 col-11 mt-3">
        <label for="">Elavon Username</label>
        <input type="text" class="form-control" v-model="elavonUserName" placeholder="Username">
      </div>
      <div class="col-md-12 col-11 mt-3">
        <label for="">Elavon Password</label>
        <input type="text" class="form-control" v-model="elavonPassword" placeholder="Password">
      </div>
      <div class="col-md-12 col-11 mt-3">
        <label for="">Default payment gateway</label>
        <select v-model="defaultPaymentGateway" class="form-control mb-3">
          <option value="Stripe">Stripe</option>
          <option value="Elavon">Elavon</option>
        </select>
      </div>
    </div> -->
    <div class="col-md-12 p-5 contentBtnAlign">
      <button class="btn submitBtn" @click="updateWebConfig">Upload</button>
      <img v-if="showWebConfigLoading" src="/img/loading.gif" alt="GIF" width="30" height="30">
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  props: [
    'webConfig',
    'requestHeader',
  ],
  data() {
    return {
      stripeSecretKey: '',
      stripePublishKey: '',
      elavonUserName: '',
      elavonPassword: '',
      defaultPaymentGateway: 'Stripe',
      showWebConfigLoading: false,
    }
  },
  mounted() {

    this.stripeSecretKey = this.webConfig.stripeSecretKey
    this.stripePublishKey = this.webConfig.stripePublishKey
    this.elavonUserName = this.webConfig.elavonUserName
    this.elavonPassword = this.webConfig.elavonPassword
    this.defaultPaymentGateway = this.webConfig.defaultPaymentGateway
  },
  methods: {
    updateWebConfig(){
      this.showWebConfigLoading = true

      // request data
      let dataToSubmit = {
        "restaurantId": localStorage.getItem('restaurantId'),
        "welcomeTxt": this.webConfig.welcomeTxt,
        "aboutUs": this.webConfig.aboutUs,
        "privacyPolicy": this.webConfig.privacyPolicy,
        "termsCondition": this.webConfig.termsCondition,
        "lat": this.webConfig.lat,
        "long": this.webConfig.long,
        "mapIframe": this.webConfig.mapIframe,
        "stripeSecretKey": this.stripeSecretKey,
        "stripePublishKey": this.stripePublishKey,
        "elavonUserName": this.elavonUserName,
        "elavonPassword": this.elavonPassword,
        "defaultPaymentGateway": this.defaultPaymentGateway,
        "headerColor": this.webConfig.headerColor,
        "navigationColor": this.webConfig.navigationColor,
        "navbarHoverColor": this.webConfig.navbarHoverColor,
        "menuIconBgColor": this.webConfig.menuIconBgColor,
        "mobileNavBgColor": this.webConfig.mobileNavBgColor,
        "alertBgColor": this.webConfig.alertBgColor,
        "alertTextColor": this.webConfig.alertTextColor,
        "alertBoxBtnBgColor": this.webConfig.alertBoxBtnBgColor,
        "alertBoxBtnFontColor": this.webConfig.alertBoxBtnFontColor,
        "welcomeTextColor": this.webConfig.welcomeTextColor,
        "welcomeBackgroundColor": this.webConfig.welcomeBackgroundColor,
        "welcomeBgShadowColor": this.webConfig.welcomeBgShadowColor,
        "sliderText": this.webConfig.sliderText,
        "headerTwoBackgroundColor": this.webConfig.headerTwoBackgroundColor,
        "aboutTextColor": this.webConfig.aboutTextColor,
        "aboutBackgroundColor": this.webConfig.aboutBackgroundColor,
        "reviewTextColor": this.webConfig.reviewTextColor,
        "reviewFontColor": this.webConfig.reviewFontColor,
        "reviewBackgroundColor": this.webConfig.reviewBackgroundColor,
        "footerColor": this.webConfig.footerColor,
        "footerTextColor": this.webConfig.footerTextColor,
        "rightsReservedTextColor": this.webConfig.rightsReservedTextColor,
        "poweredByColor": this.webConfig.footerPoweredByColor,
        "categoryBgForDesktop": this.webConfig.categoryBgForDesktop,
        "categoryBgForMobile": this.webConfig.categoryBgForMobile,
        "categoryHoverBgColor": this.webConfig.categoryHoverBgColor,
        "categoryHoverTextColor": this.webConfig.categoryHoverTextColor,
        "cartItemBgColor": this.webConfig.cartItemBgColor,
        "buttonColor": this.webConfig.buttonColor,
        "buttonFontColor": this.webConfig.buttonFontColor,
        "contactPageBgColor": this.webConfig.contactPageBgColor,
        "openingTime": this.webConfig.openingTime,
        "closingTime": this.webConfig.closingTime,
        // "timeSlotApplicable": this.timeSlotApplicable,
        "allergyInformation": this.webConfig.allergyInformation,
        "lunchOpeningHours": this.webConfig.lunchOpeningHours,
        "socialMedia": this.webConfig.socialMedia,
        "webAddress": this.webConfig.webAddress
      }

      // request url
      let url = process.env.VUE_APP_API_URL+'/api/webConfig/update'

      // send request
      axios
        .post(url, dataToSubmit, {
          headers: this.requestHeader
        })
        .then((data) => {
          if (data.status == 200) {
            this.showWebConfigLoading = false
            this.$toastr.s("Successfully Updated.", "Success");
            setTimeout(()=> {this.$router.go()}, 1000)
          }
        })
    },
  }
}
</script>
